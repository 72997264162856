@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import "~react-perfect-scrollbar/dist/css/styles.css";

@import "./mixins/functions";
@import "./variables";
@import "./general";
@import "./typography";
@import "./inputs";
@import "./buttons";
@import "./card";
@import "./helper";
@import "./pages";

@media (min-width: 1400px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 100%;

    // padding-left: 65px !important;
    // padding-right: 65px !important;
  }
}

.brw-px-1 {
  border-width: 1px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.pointer {
  cursor: pointer;
}

.br-15 {
  border-radius: 15px !important;
}

.bg-primary {
  background-color: $primary;
}

.text-white {
  color: #fff;
}

.text-ghost {
  --bs-text-opacity: 1;
  --bs-ghost-rgb: 204, 204, 214;
  color: rgba(var(--bs-ghost-rgb), var(--bs-text-opacity)) !important;
}

.tt-capitalize {
  text-transform: capitalize;
}

.graph-filter-select {
  font-size: 1rem;
  padding: 0 0.5rem 0.25rem 0.5rem;
  border: 0;
  border-bottom: 1px solid #ccc;
}

.carousel-indicators {
  bottom: -15px !important;

  button {
    border-radius: 50%;
    width: 10px !important;
    height: 10px !important;
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: 0px;
}

.carousel-inner {
  padding: 0;
}

// Timeline Calendar
.timeline-calendar {
  padding-top: 20px;
  padding-right: 30px;
  padding-left: 30px;
  width: 370px;
  padding-bottom: 100px;
  position: relative;
  background: white;
  display: flex;
  border: 1px solid rgba(224, 224, 224, 1);

  button:nth-child(3) {
    border: none;
    background: none;
    position: absolute;
    right: 30px;
    top: 25px;
    width: 23px;
    height: 28px;
    overflow: hidden;
  }

  button:nth-child(2) {
    border: none;
    background: none;
    position: absolute;
    //width: 23px;
    left: 285px;
    top: 25px;
    height: 28px;
    overflow: hidden;
  }

  .react-datepicker__navigation {
    .react-datepicker__navigation-icon--previous {
      &::before {
        height: 12px;
        width: 12px;
        left: 0;
        border-color: #000;
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: "";
        display: block;
        margin-top: 10px;
        transform: rotate(228deg);
      }
    }

    .react-datepicker__navigation-icon--next {
      &::before {
        height: 12px;
        width: 12px;
        border-color: #000;
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: "";
        display: block;
        margin-top: 10px;
        transform: rotate(45deg);
      }
    }
  }

  .react-datepicker__month-container {
    width: 100%;
    padding: 0;
    // float:left;
    // padding: 20px;

    .react-datepicker__header {
      background-color: #fff;
      border: 0;

      .react-datepicker__current-month {
        text-align: left;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 35px;
        font-size: 22px;
        font-weight: normal;
      }

      .react-datepicker__day-names {
        display: flex;
        justify-content: center;
        font-size: 12px;

        .react-datepicker__day-name {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
        }
      }
    }

    .react-datepicker__month {
      color: #fff;
      margin: 0;

      // background: #22A2FF;
      .react-datepicker__week {
        display: flex;
        justify-content: center;

        .react-datepicker__day {
          width: 100%;
          border: 1px solid #fff;
          height: 45px;
          color: #fff;
          display: flex;
          margin: 0;
          align-items: center;
          justify-content: center;
          background: #ccccd6;
        }

        .react-datepicker__day--highlighted {
          background-color: #22a2ff;
        }

        .react-datepicker__day--outside-month {
          background-color: #ccccd6;
        }

        // .react-datepicker__day--keyboard-selected {
        //   background-color: #f04858;
        // }
        .react-datepicker__day--selected,
        .react-datepicker__day--in-range {
          background-color: #000034;
        }
      }
    }
  }
}
.react-datepicker {
  z-index: 1000;
  &__input-container {
    input {
      &::placeholder {
        font-style: Helvetica-Neue-Regular;
        color: $gray-550 !important;
        font-weight: 400;
      }
    }
  }

  &__month-select,
  &__year-select {
    padding: 0.5rem !important;
    height: auto !important;
  }
}

.custom-radio {
  .form-check-input {
    height: 14px;
    width: 14px;
  }

  .form-check-label {
    position: relative !important;
    font-size: 30px;
    cursor: pointer;
  }
}

.custom-table {
  border-collapse: separate;
  border-spacing: 0 5px;

  thead > tr > th {
    color: black;
    font-weight: bold;
    font-size: 12px;
    padding: 5px 8px 0px;
    text-align: left;
  }

  tbody > tr > td {
    padding: 10px;
    font-size: 14px;
  }

  tbody > tr {
    background: #eeeeee59;
    border: 1px solid #eeeeee59;
  }
}

.black-color {
  background: #1f1d1d;
}

.active-cart-text {
  font-weight: 700;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: 2px;
  color: $white;
}

.active-cart {
  width: 100%;
  margin-bottom: 20px;

  .home-card {
    width: 100%;
    height: 310px;
  }
}

.show-all {
  font-size: 16px;
  font-weight: 500;
  color: $gray-310;
  cursor: pointer;
}
.home-card-options {
  position: relative;
  // width: 24px;
  // height: 24px;
  // right: 24px;
  // top: 20px;
  cursor: pointer;
  z-index: 2;
  display: none;

  .dropdown-menu-button {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}

.home-card:hover .home-card-options {
  display: block;
}

.home-card-text-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(16, 10, 0, 0) 0.01%,
    #100a00 100%
  );
  border-radius: 0 0 13px 13px;

  &.product-info {
    display: none;
  }
}

.home-card:hover .home-card-text-container.product-info {
  display: block;
}
.home-card:hover .liked-icon {
  display: none;
}

.heart-icons {
  width: 20px;
  height: 17px;
  margin-right: 20px;
}

.cart-save {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 60px;

  svg {
    display: none;
    margin-bottom: 15px;
    cursor: pointer;
    margin-left: -27px;
  }

  .edit_wardrobe {
    display: none;
    margin-bottom: 15px;
    cursor: pointer;
    margin-left: -30px;
  }

  .heart-icons {
    display: none;
    margin-bottom: 8px;
    margin-left: -27px;
    cursor: pointer;
  }
}

.home-card:hover svg {
  display: block !important;
}

.home-card:hover .edit_wardrobe {
  display: block !important;
}

.home-card:hover .list-card-content > .image {
  filter: brightness(0.5);
}
.home-card:hover .list-card-content > img {
  filter: brightness(0.5);
}

.home-card:hover .heart-icons {
  display: block !important;
}

.home-cared.product-card {
  .user-info {
    display: none;
  }

  .cart-heart {
    display: none;
  }

  .home-card:hover .user-info {
    display: block !important;
  }

  .home-card:hover .cart-heart {
    display: flex !important;
  }
}

.cart-text {
  font-size: 20px;
  text-transform: capitalize;
}

.text-wrap-home {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
  max-width: 100%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.showMoreText {
  text-decoration: none;
  display: inline-block;
  font-weight: 600;
  cursor: pointer;
}

.form-check-input {
  border-radius: 2px !important;
  padding: 0.3rem;
  border: 1px solid $black-100;
  z-index: 100;
  position: relative;

  &:checked {
    background-color: $black-200;
    border-color: $black-200;
  }
}

// Extension Pages design

.extension-brands {
  width: 364px;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0px 10px 0px 0px #5d412d;
  background-color: #e5b15e;

  .rounded-arrow {
    padding: 16px;
    border-radius: 44px;
    background: #5d412d;
    box-shadow: 1px 1px 0px 0px #000;
    gap: 10px;
    display: flex;
    cursor: pointer;
    color: white;
  }
}
.modal-custom {
  height: calc(100% - 70px);
  margin-top: 70px;

  .close-modal-btn {
    width: 55px;
    height: 55px;
    filter: drop-shadow(2px 2px 0px #b2d3a8);
    background-color: #00cc8f;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
  }

  .modal-side {
    margin-top: 120px;
    width: 50%;
    .list-unstyled {
      width: 435px;
      margin: 0px auto;
      li {
        height: 178px;
        position: relative;
        .list-img {
          width: 36px;
          height: 36px;
          background-color: white;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          &::after {
            content: " ";
            position: absolute;
            height: 137px;
            top: 35px;
            border: 1px dashed white;
          }
        }

        &:last-child {
          height: 110px;
          .list-img {
            &::after {
              border: none;
            }
          }
        }
        .list-header {
          font-family: Helvetica-Neue-Regular !important;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          color: white;
          padding-left: 25px;
        }
        .list-description {
          color: #9a9a9a;
          font-family: Helvetica-Neue-Regular !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          padding-left: 25px;
        }
      }
      .active {
        .list-img {
          background-color: #e5b15e;
          &::after {
            border: 1px dashed #e5b15e;
          }
        }
      }
    }
    .bottom-container {
      width: 310px;
      margin: 0px auto;
      .modal-btn {
        display: flex;
        width: 173px;
        height: 48px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: #00cc8f;
        box-shadow: 0px 3px 0px 0px #b2d3a8;
        font-family: Helvetica-Neue-Regular !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        border: none;
        color: white;
      }
    }
  }
}
