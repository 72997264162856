@import "~bootstrap/scss/bootstrap";

body {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  font-size: $base-font-size;
  font-family: $primary-font-family;
}

#root {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

span.form-error {
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  font-size: 14px;
  font-weight: 500;
}

@keyframes detect-scroll {
  from,
  to {
    --can-scroll: ;
  }
}

.loading-indicator {
  position: fixed;
  height: 5px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: tint(#e5b15e, 75%);
  overflow: hidden;

  .line {
    position: absolute;
    opacity: 0.4;
    background-color: #e5b15e;
    width: 150%;
    height: 5px;
  }

  .subline {
    position: absolute;
    background-color: #e5b15e;
    height: 5px;
  }

  .inc {
    animation: increase 2s infinite;
  }

  .dec {
    animation: decrease 2s 0.5s infinite;
  }

  @keyframes increase {
    from {
      left: -5%;
      width: 5%;
    }

    to {
      left: 130%;
      width: 100%;
    }
  }

  @keyframes decrease {
    from {
      left: -80%;
      width: 80%;
    }

    to {
      left: 110%;
      width: 10%;
    }
  }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit.fade-exit-active,
.fade-exit.fade-exit-done {
  position: absolute !important;
  z-index: -1;
  width: 0px;
  height: 0px;
  overflow: hidden;

  & > * {
    width: 0px;
    height: 0px;
  }
}

.MuiTableCell-root {
  font-size: 0.85rem !important;
  font-weight: 500 !important;
}

.MuiTableCell-footer {
  border: 0 !important;
}

.table-toolbar {
  span.applied-filters {
    max-width: 100%;
    overflow-x: auto;
    display: inline-block;

    span.badge {
      font-weight: 500;
      padding: 7px 30px 7px 7px;
      position: relative;
      display: inline-block;
      margin: 0px 5px 4px 0px;

      & > svg {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.portal,
.filter-drawer {
  &__header {
    box-shadow: $box-shadow;
    backdrop-filter: blur(6px);
    background-color: rgba(250, 250, 250, 0);
  }
}

.daterangepicker {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px !important;
}

table {
  &.table {
    &--details {
      & > tbody {
        & > tr {
          & > th,
          & > td {
            padding: 1rem 1rem !important;
          }

          & > th {
            color: $gray-500 !important;
            font-weight: 500 !important;
            font-size: 0.85rem !important;
            vertical-align: middle !important;
            width: 150px !important;
            border-radius: 7px 0px 0px 7px !important;
          }

          & > td {
            font-weight: 500 !important;
            color: $gray-400 !important;
            vertical-align: middle !important;
          }

          & > td {
            &:last-child {
              font-weight: 600 !important;
              color: $gray-800 !important;
              vertical-align: middle !important;
              border-radius: 0px 7px 7px 0px !important;
            }
          }

          &:nth-child(odd) {
            & > * {
              background-color: $gray-50 !important;
            }
          }
        }
      }
    }
  }
}

.layout {
  &--blank {
    background-color: $black;

    .header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: $header-height;
      background-color: $black-200;
    }

    .content {
      height: 100%;
      overflow: auto;
    }
  }

  &--default {
    background-color: $black;
    color: $white;
    position: relative;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px;

    .header {
      box-shadow: none;
      min-height: $header-height;
      background-color: $black-200;
      border-radius: $border-radius-layout;
      box-shadow: 0px 0px 5px $black, 0px -10px 5px $black;

      // position: relative;
      //search-bar
      .input-wrapper {
        border-radius: $border-radius-search-bar;
        border: 1px solid transparent;
      }

      .search-bar-container {
        display: flex;
        align-items: center;
        min-width: 433px;
        border-radius: $border-radius-search-bar;
        background: $black-300;
        border: none;
        position: absolute;
        left: calc(#{$sidebar-width} + 2rem);

        @media screen and (max-width: 768px) {
          left: $sidebarSmall-width;
          min-width: 300px;
        }

        .search-icon-container {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $gray-250;
          padding-left: 12px;
        }

        .search-input {
          background: transparent;
          // padding: 10.5px 12px 11px 12px !important;
          border: none !important;
          height: 50px;
          color: $white;
        }

        .search-input::placeholder {
          width: 255px;
          font-family: Helvetica, sans-serif !important;
          font-size: 14px;
          color: $gray-250 !important;
        }

        .search-input:focus {
          box-shadow: none !important;
        }

        // SearchResult Container

        .category-heading {
          font-family: Helvetica-Neue-Medium;
          font-size: 14px;
          color: $white;
          margin-block: 10px;
        }

        .search-results {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          min-width: 433px;
          max-height: 60vh;
          overflow-y: auto;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          padding: 8px 12px;
          margin-top: 10px;
          background: $black;
          box-shadow: 0px 5px 13px 2px rgba(93, 65, 45, 0.25);
          border-radius: 8px;
          overflow-x: hidden;

          &::-webkit-scrollbar {
            width: 10px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            border-radius: 5px;
            background-color: #d9d9d9;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background-color: $black-300;
            border-radius: 5px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }

          .result-text {
            font-family: Helvetica-Neue-Medium !important;
            font-size: 16px;
            line-height: 125%;
            color: $white;
          }

          .card-search-list {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;
          }

          .user-card {
            display: flex;
            align-items: center;
            background-color: #262626;
            border-radius: 8px;
            padding: 3px;
            width: 100%;
            cursor: pointer;

            .profile-image {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin: auto 18px;

              img {
                object-fit: cover;
              }
            }

            .user-details {
              h3 {
                text-transform: capitalize;
                margin-bottom: 0px;
                font-size: 16px;
              }

              p {
                font-size: 14px;
                color: #666;
              }
            }
          }

          .search-results-filter-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 12px;
            width: 100%;

            .filter-heading {
              height: 18px;
              font-family: "Helvetica-Neue-Medium" !important;
              font-size: 14px;
              line-height: 125%;
              color: #f7f7f7;
            }

            .filter-tabs-container {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              align-items: flex-start;
              padding: 0px;
              gap: 8px;
              width: 100%;

              .filter-tabs {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8px 12px;
                gap: 12px;
                height: 24px;
                background-color: transparent;
                border: 1px solid #c4c4c4;
                border-radius: 12px;
                cursor: pointer;

                span {
                  font-family: "Helvetica-Neue-Regular" !important;
                  font-size: 14px;
                  line-height: 100%;
                  display: flex;
                  align-items: center;
                  text-align: center;
                  color: #c4c4c4;
                }
              }

              .filter-tabs-active {
                @extend .filter-tabs;
                border: 1px solid transparent;
                background-color: #e5b15e;

                span {
                  font-family: "Helvetica-Neue-Medium" !important;
                  color: #000000;
                }
              }
            }
          }

          .recent-search-container {
            display: flex;
            flex-direction: column;
            padding: 0px;
            width: 100%;
          }

          .recent-search-heading {
            font-family: Helvetica-Neue-Medium;
            font-size: 14px;
            color: $white;
            margin-block: 10px;
          }

          .search-text-result {
            font-size: 16px;
            line-height: 18px;
            font-weight: 400;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .recent-search {
            display: flex;
            flex-direction: column;
            padding: 0px;
            gap: 12px;
            height: max-content;

            .recent {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding: 0px;
              gap: 12px;
              height: 24px;

              .name-and-recent-icon {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 8px;
                cursor: pointer;
                max-width: 90%;
              }
            }
          }

          // Search-Lists
          .lists-result {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
          }

          .search-list-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 8px;
            width: 100%;

            p {
              height: 18px;
              font-family: "Helvetica-Neue-Medium" !important;
              font-size: 14px;
              line-height: 125%;
              color: $white;
              cursor: pointer;
            }
          }

          // Search-tags

          .suggested-tabs-container {
            display: flex;
            flex-direction: row;
            gap: 12px;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;

            .suggested-tabs {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 6px 8px;
              gap: 12px;
              // height: 24px;
              background-color: transparent;
              border: 1px solid $white;
              border-radius: 12px;
              cursor: pointer;

              span {
                font-family: Helvetica-Neue-Regular !important;
                font-size: 14px;
                line-height: 100%;
                display: flex;
                align-items: center;
                text-align: center;
                color: $white;
              }
            }
          }
        }
        

        // Add media queries for responsiveness
        @media screen and (max-width: 768px) {
          .search-results {
            .user-card {
              width: 100%;
            }
          }
        }
      }

      .header-buttons {
        display: flex;
        justify-content: space-between;
        margin-right: 10px;
        gap: 14px;

        .header-login {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px 12px;
          gap: 12px;
          width: 68px;
          height: 32px;
          background: $white;
          border-radius: $border-radius-1;
          border: none;

          span {
            width: 44px;
            height: 16px;
            font-family: "Helvetica-Neue-Medium";
            font-size: 16px;
            line-height: 100%;
            text-align: center;
            color: $black-100;
          }
        }

        .header-signup {
          @extend .header-login;
          justify-content: flex-start;
          width: 74px;
          background: $black-100;
          border: 1px solid $white;
          span {
            color: $white;
          }
        }
      }
    }

    aside {
      position: fixed;
      width: $sidebar-width;
      left: 5px;
      // 88px is the height of header
      // 15px is sum of vertical margins
      height: calc(100% - 88px - 15px);
      padding-inline: 35px;
      padding-top: 40px;
      background-color: $black-200;
      border-radius: $border-radius-layout;

      @media screen and (max-width: 768px) {
        width: $sidebarSmall-width;
      }

      .sidebar-items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        width: 100%;
        height: 18px;
      }

      .sidebar-navigation {
        display: flex;
        gap: 35px;
        align-items: center;

        svg {
          stroke: $white;
        }

        &.selected {
          &:hover {
            color: $white;
            svg {
              stroke: $white;
            }
          }
        }

        &:hover {
          color: $gray-250;
          svg {
            stroke: $gray-250;
          }
        }
      }

      .your-lists-items-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        margin-top: 35px;
        height: max-content;
        width: 100%;

        .your-lists-items__wishlist {
          min-height: 45px;
          height: 45px;
          width: 100%;
          display: flex;
          gap: 15px;

          .image-wrapper {
            height: 45px;
            width: 45px;
            background: $white;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .list-cover {
            object-position: center;
            object-fit: contain;
            height: 100%;
            width: 100%;
          }

          .title {
            font-size: 18px;
            width: 180px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .subtitle {
            font-size: 14px;
            color: $gray-250;
          }
        }

        .your-lists-items {
          display: flex;
          flex-direction: column;
          padding: 0px;
          padding-left: 8px;
          list-style: none;
          overflow-y: auto;
          gap: 16px;
          width: 100%;
          max-height: calc(63vh - 9rem);

          // Custom scrollbar styling
          &::-webkit-scrollbar {
            width: 8px;
          }

          &::-webkit-scrollbar-track {
            border-radius: 5px;
            background-color: #363636;
          }

            &::-webkit-scrollbar-thumb {
            background-color: $gray-800;
            border-radius: 5px;
            transition: background-color 0.3s ease;

            &:hover {
              background-color: $gray-500;
            }
            }

          .li-item {
            width: max-content;
            font-size: 16px;
            line-height: 150%;
            display: flex;
            align-items: center;
            letter-spacing: 0.005em;
            color: $gray-550;
            cursor: pointer;
          }
        }

        .selected {
          .title {font-weight: 700 !important;}
        }
      }

      .create-new {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2px;
        width: 100px;
        height: 38px;
        background: $white;
        border-radius: $border-radius-1;
        color: $black-300;
        cursor: pointer;
        border: none;
        margin-left: 3.5rem;
        padding-left: 8px;
        margin-bottom: -1rem;
        margin-top: -1.8rem;

        p {
          font-size: 16px;
          font-family: "Helvetica-Neue-Bold";
          font-weight: 400;
          text-align: center;
          color: $black-300;
          letter-spacing: 0.08px;
        }
      }

      .nav {

        a {
          &.nav-item {
            height: auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-bottom-right-radius: 32px;
            border-top-right-radius: 32px;
            box-shadow: none;
            font-size: 20px;
            line-height: 100%;
            font-weight: 400;
            letter-spacing: 0.005em;
            color: $white;


            @media screen and (max-width: 768px) {
              justify-content: center;
              margin-top: 0.375rem;
            }

            &.active {
              box-shadow: none;
              font-size: 20px;
              line-height: 100%;
              font-weight: 500;
              letter-spacing: 0.005em;
              color: $white;
            }
          }
        }
      }
    }

    .room-show {
      width: $sidebarSmall-width;
    }

    main {
      margin-left: $sidebar-width + 5px;
      width: calc(100% - #{$sidebar-width});
      min-height: calc(100vh - 88px - 15px);
      height: calc(100%);
      background: $black-200;
      border-radius: $border-radius-layout;
      padding: 30px;

      // animation: detect-scroll linear;
      // animation-timeline: scroll(self);
    
      // --height-if-can-scroll: var(--can-scroll) 10px;
      // --height-if-cant-scroll: calc(100vh - 88px - 15px);
      // height: var(--height-if-can-scroll, var(--height-if-cant-scroll));

      @media screen and (max-width: 768px) {
        margin-left: $sidebarSmall-width;
        width: calc(100% - #{$sidebarSmall-width});
      }
    }
  }
}

.nodata-link-text {
  width: max-content;
  font-size: 18px;
  line-height: 100%;
  color: #fff;
  padding: 0;
}

// Top Brands Container
.top-brand-container {
  .list-card-content {
    border: 1px solid $gray-750;
    border-radius: 16px;
  }
  .text-container {
    font-family: "Helvetica-Neue-Medium";
    padding: 1rem;
    p {
      font-size: 24px;
      line-height: 100%;
      color: $white;
    }

    width: 100%;
    background-color: $black-100;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 16px 16px;
  }
}

//dropdown menuitem STYLES
// .dropdown-menuitem {
//   width: 150x !important;
//   padding: 8px !important;
//   display: flex !important;
//   flex-direction: column !important;
//   align-items: flex-start !important;

//   .dropdown-menuitem-items {
//     display: flex !important;
//     flex-direction: row !important;
//     align-items: center !important;
//     gap: 8px !important;
//     width: 100%;
//     &.room-dropdown:hover {
//       color: $black-100;
//     }

//     &.profile-dropdown:hover {
//       color: $black-100;
//     }
//     .item-name {
//       font-size: 16px !important;
//       line-height: 100% !important;
//       font-weight: 500;
//     }

//     &:hover {
//       color: $white;
//     }
//   }
// }

// dropdown-menu
.sort-menu {
  .MuiMenu-paper {
    margin-top: 1rem;
    border-radius: 8px;
  }

  &.profile {
    .MuiMenu-paper {
      padding: 0.7rem !important;
    }

    .dropdown-menuitem {
      border-bottom: 1px solid $gray-150 !important;
    }
  }
}

//ONBOARDING__INTERSET__MODAL
.onboarding-interest-popup-modal {
  background: black;
  padding-top: 115px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .popup-title {
    color: $white;
    font-size: 60px;
    line-height: 60px;
    font-weight: 700;
  }

  .popup-subtitle {
    color: $gray-360;
    font-size: 24px;
    margin-top: 20px;
  }

  .tags-grid-wrapper {
    padding-top: 65px;
    padding-bottom: 70px;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px;
    width: 100%;
    padding-inline: 78px;
  }

  .style-tag {
    border: 1px solid $white;
    background: transparent;
    color: $white;
    height: 60px;
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px 32px;
    font-size: 24px;
    font-weight: 600;

    &.active {
      background: $white;
      color: $black;
    }
  }

  .start-shopping-text {
    margin-bottom: 72px;
    font-size: 18px;
    color: $gray-360;

    font {
      color: $white;
      margin-left: 4px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.modal-header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0px 0px;
  gap: 20px;
  position: absolute;
  width: 100%;
  height: 70px;
  left: 0px;
  top: 0px;
  background: $black-100;

  .modal-header {
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 40px;
    width: 100%;
    border: none;

    p {
      font-family: "Helvetica-Neue-Medium";
      font-size: 20px;
      line-height: 100%;
      letter-spacing: 0.005em;
      color: #ffffff;
    }
  }

  .avatar-container {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    overflow: hidden;
    background: $gray-310;

    .avatar-image {
      aspect-ratio: 1/1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.modal-close-btn {
  position: absolute;
  right: 9%;
  top: 15%;
  cursor: pointer;

  .modal-close-btn:hover {
    scale: 0.99;
  }
}

//USER__ACCOUNT_INFO

.user-account-information {
  .image-uploader-container {
    text-align: center;
    position: relative;
  }

  .image-container {
    position: relative;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    background-color: #f0f0f0;
  }

  .placeholder-image {
    width: 130px;
    padding: 15px;
  }

  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .upload-button {
    position: absolute;
    bottom: 15%;
    right: 28%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $warning-1;
    border: 3px solid $white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .upload-icon {
    font-size: 20px;
    color: #333;
  }
}

//Login styling
.social-login-icon {
  span {
    button {
      border: none !important;
      background: transparent !important;
      color: transparent !important;
      width: 44px;
      height: 44px;
      padding: 0;
    }

    svg {
      width: 44px;
      height: 44px;
      margin-right: 28px;
    }
  }
}

//VIEW__PROFILE__STYLING

//MODAL
.modal-content {
  background-color: transparent !important;
  border: none !important;
}

.modal-header {
  border-bottom: 1px solid #e5e5e5;
}

//View Profile Page
.profile {
  .profile-container {
    width: 100%;
    padding: 38px 0px;
    // border: 2px solid rgba(240, 240, 240, 0.13);
    border-radius: 8px;

    .profile-card {
      display: flex;
      justify-content: center;
      width: 70%;
      min-width: 70%;
      margin: 0 auto;

      .name-edit-button {
        display: flex;
        justify-content: space-between;
        width: max-content;
        gap: 32px;

        .profile-card-name {
          font-family: "Helvetica-Neue-Bold";
          font-size: 36px;
          color: $gray-150;
          font-weight: 700;
        }

        .profile-edit-btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px 12px;
          gap: 12px;
          width: 92px;
          height: 32px;
          background: $white;
          border-radius: $border-radius-search-bar;
          border: none;

          span {
            font-size: 16px;
            line-height: 100%;
            text-align: center;
            color: $black-100;
            font-family: "Helvetica-Neue-Bold";
            font-weight: 700;
          }

          &.share {
            border: 1px solid $white;
            border-radius: $border-radius-1;
            background-color: transparent;
            span {
              color: $white;
            }
          }
        }
      }

      .profile-card-username {
        font-family: "Helvetica-Neue-Medium";
        font-size: 24px;
        line-height: 28px;
        color: rgba(217, 217, 217, 0.44);
      }

      .profile-card-values {
        font-family: "Helvetica-Neue-Bold";
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        letter-spacing: 0.005em;
        color: $gray-350;
      }

      .profile-card-items {
        font-family: "Helvetica-Neue-Regular";
        font-size: 18px;
        line-height: 100%;
        color: $gray-520;
      }

      .profile-card-desc {
        font-family: "Helvetica-Neue-Regular";
        font-size: 16px;
        line-height: 130%;
        color: $gray-520;
      }
    }
  }

  @media screen and (min-width: 400px) and (max-width: 1200px) {
    .profile-container .profile-card {
      width: 90% !important;
    }
  }
}


// EDIT__PROFILE__STYLING
.edit-profile-info {
  // .my-tabs {
  //   margin: auto -2.3rem;
  //   .nav-link {
  //     font-family: "Helvetica-Neue-Regular";
  //     border: none;
  //     font-style: normal;
  //     font-weight: 400;
  //     font-size: 16px;
  //     line-height: 100%;
  //     color: $gray-550;
  //     width: 155px;
  //   }
  //   &.profile .nav-link {
  //     font-size: 24px;
  //     font-weight: 700;
  //     width: 11rem;
  //   }
  // }
  // .nav-tabs {
  //   border-bottom: 1px solid $gray-720;
  // }

  // .nav-link {
  //   font-family: "Helvetica-Neue-Medium";
  //   font-size: 16px;
  //   color: #333;
  //   font-weight: 700;
  //   font-size: 24px;
  //   line-height: 100%;
  //   color: $warning-1;
  // }

  // .my-tabs .nav-link.active {
  //   border-bottom: 2.5px solid $white;
  //   color: $white;
  //   background-color: transparent;
  //   font-family: "Helvetica-Neue-Bold";
  //   isolation: auto;
  // }

  // .my-tab {
  //   border: none;

  //   .my-tab button {
  //     color: #333;
  //     font-weight: bold;
  //     border: none;
  //   }

  //   .my-tab button:hover {
  //     color: $warning-1;
  //     border: none;
  //   }

  //   .main-container {
  //     width: 80%;
  //   }

  //   .form-error {
  //     top: 100%;
  //     left: 50%;
  //     transform: translateX(-50%);
  //   }
  // }

  //Personal Tab content Styling

  .edit-profile-info__personal {
    .profile-edit__input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 16px;
      height: 48px;
      background: $black-200;
      border-radius: $border-radius-1;
      border: none;
      color: $white;

      .profile-edit__input::-webkit-calendar-picker-indicator {
        filter: invert(54%) sepia(0%) saturate(0%) hue-rotate(187deg)
          brightness(100%) contrast(102%) !important;
      }
    }

    //profile-info-card styles

    .profile-info-col {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .profile-info-edit-image {
      cursor: pointer;
      background: none;
      border: none;
      margin: 0;
      padding: 0;

      span {
        font-family: Helvetica-Neue-Regular !important;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0.005em;
        color: #e5b15e;
      }
    }

    //image upload styles
    .image-uploader-container {
      text-align: center;
      position: relative;
    }

    .image-container {
      position: relative;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      overflow: hidden;
      display: inline-block;
      background-color: #f0f0f0;
      box-shadow: 0px 4px 24px 4px rgba(229, 177, 94, 0.15) !important;
    }

    .placeholder-image {
      width: 150px;
      padding: 15px;
    }

    .image-container img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .upload-button {
      position: absolute;
      bottom: 9%;
      right: -3%;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $warning-1;
      border: 3px solid $black-100;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .upload-icon {
        font-size: 20px;
        color: #333;
      }
    }

    .profile-info-image {
      box-sizing: border-box;
      width: 150px;
      height: 150px;
      border: 1px solid $white;
      box-shadow: 0px 4px 24px 4px rgba(229, 177, 94, 0.15) !important;
    }

    .profile-info-name {
      font-family: Helvetica-Neue-Medium;
      font-size: 24px;
      line-height: 120%;
      font-weight: 500;
      color: $white;
    }

    @media screen and (max-width: 768px) {
      .profile-info-name {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
      }

      .profile-info-username {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }

    .profile-info-username {
      font-family: Helvetica-Neue-Medium;
      font-size: 16px;
      line-height: 120%;
      font-weight: 500;
      color: $gray-350;
    }

    .profile-edit-reset {
      font-family: Helvetica-Neue-Medium;
      font-size: 18px;
      font-weight: 500;
      background-color: transparent;
      text-align: center;
      color: $gray-520;
      margin-right: 25px;
    }

    .profile-edit-save-changes {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px;
      gap: 12px;
      width: 138px;
      border-color: $gray-550;
      border-width: 1px !important;
      border-radius: $border-radius-1;
      background-color: transparent;

      span {
        font-family: "Helvetica-Neue-Regular";
        font-size: 16px;
        line-height: 80%;
        text-align: center;
        color: $gray-550;
      }
    }

    //personal-info-card styles

    .personal-info-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      gap: 24px;
      background: $black-100;
      border-radius: 1.5rem;

      .bio-container {
        font-family: "Helvetica-Neue-Medium";
        font-size: 16px;
        line-height: 100%;
        color: $white;
        padding: 12px 16px;
        background: $black-200;
        border-radius: 8px;
        border: none;
        box-shadow: unset;
      }

      #bio-counter {
        position: absolute;
        bottom: -28px;
        left: 0;
        font-family: Helvetica-Neue-Regular;
        font-size: 12px;
        color: $gray-520;
      }
    }

    .profile-edit-card-heading {
      font-family: "Helvetica-Neue-Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      color: $white;
    }

    .form-label-heading {
      font-family: Helvetica-Neue-Regular;
      line-height: 80%;
      color: $gray-520;
      font-size: 16px;
      line-height: 80%;
    }
  }

  //Account Tab content Styling

  .edit-profile-info__account {
    .profile-edit-card-heading {
      font-family: "Helvetica-Neue-Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      color: $white;
    }

    .account-profile-edit-togle-text {
      font-family: Helvetica-Neue-Regular;
      font-size: 16px;
      line-height: 125%;
      color: $white;
      font-weight: 500;
    }

    .account-profile-edit-togle-subtext {
      font-family: Helvetica-Neue-Regular;
      font-size: 16px;
      line-height: 120%;
      color: $gray-540;
    }

    .togle-item-container {
      padding: 24px 60px 24px 24px;
      height: 85px;
      background: $black-100;
      border-radius: 8px;
    }

    .account-profile-edit-options {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;
      margin-top: 100px;
    }
  }
}

.account-settings-heading {
  font-size: 30px;
  font-weight: 700;
  color: $white;
  margin-bottom: 20px;
}

//PROFILE INFO 

.profile-info-wrapper {
  min-width: 586px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// ACCOUNT PRIVACY

.account-privacy-wrapper {
  min-width: 586px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.account-settings-text {
  font-size: 20px;
  font-weight: 400;
  color: $white;
}

.account-settings-subtext {
  font-size: 18px;
  font-weight: 400;
  color: $gray-310;
  width: 430px;
}

.account-settings-label {
  font-size: 20px;
  font-weight: 400;
  color: $white;
}

.account-profile-text-btn {
  cursor: pointer;
}

.account-profile-delete-btn {
  color: $danger;
  cursor: pointer;
}

// ORDER_SETTINGS

.order-settings {
  padding-left: 1%;

  .container-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    p {
      font-weight: 700;
      font-size: 32px;
      line-height: 100%;
      font-family: "Helvetica-Neue-Bold";
      color: $white;
    }

    .import-cards-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 0px;
      gap: 57px;

      .import-cards {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 48px 24px;
        gap: 18px;
        width: 228px;
        height: 228px;
        background: $gray-150;
        border: 1px solid $gray-720;
        border-radius: 24px;
        cursor: pointer;

        .import-cards-text {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0px;
          gap: 8px;
          width: 184px;
          height: 38px;

          p {
            font-family: "Helvetica-Neue-Regular";
            font-size: 16px;
            line-height: 100%;
            color: $black-200;
            font-weight: 500;
          }

          span {
            font-family: "Helvetica-Neue-Regular";
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.005em;
            font-weight: 700;
            color: $black-200;
          }
        }
      }
    }
  }
}

//Add Source Modal
.add-source-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 8px 0px;
  gap: 8px;
  width: 384px;
  border-bottom: 2px solid $gray-150;

  .add-source-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    width: 100%;
    height: 35px;

    p {
      font-weight: 700;
      font-size: 28px;
      line-height: 100%;
      letter-spacing: 0.005em;
      color: $black-200;
    }
  }

  .add-source-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 384px;
    height: 186px;

    .add-source {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 12px;
      gap: 47px;
      width: 384px;
      height: 48px;
      border: 2px solid $gray-150;
      border-radius: 8px;

      .source {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 8px;
        width: max-content;
        height: 24px;

        img {
          width: 24px;
          height: 24px;
        }

        p {
          font-size: 20px;
          line-height: 100%;
          letter-spacing: 0.005em;
          color: #000000;
        }
      }
    }

    .add-source-text {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 4px;
      width: 384px;
      height: 42px;
      font-family: "Helvetica-Neue-Medium";
      font-size: 14px;
      line-height: 150%;
      color: $gray-720;

      .shield-text {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.005em;
        color: $gray-720;
        font-weight: 500;
        font-family: "Helvetica-Neue-Medium";

        span {
          color: $green-100;
          cursor: pointer;
        }
      }
    }
  }
}

//Notification Section

.notification-section {
  width: 380px;
  height: auto;
  position: absolute;
  z-index: 2;
  right: 15%;
  background: #000000;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px;
  gap: 32px;

  .image-section-notify {
    margin-left: 12px;
    cursor: pointer;
  }

  .text {
    font-weight: 700;
    font-size: 24px;
    font-family: "Helvetica-Neue-Bold";
  }

  .border-notify {
    border: 1px solid $gray-720;
  }

  .time {
    font-size: 16px;
    color: $gray-350;
  }

  .head-notify {
    font-size: 16px;
  }

  .desc-notify {
    font-size: 12px;
    color: $gray-350;
    margin-top: 4px;
  }

  .price-notify {
    font-weight: 700;
    font-size: 14px;
    color: $gray-350;
  }
}

// Balance Header
.main-slider {
  .sliderHandle:after {
    cursor: pointer !important;
    right: -7px !important;
    border: 1px solid #000000 !important;
    box-shadow: 1px 3px 11px rgba(10, 132, 255, 0.21) !important;
  }

  .sliderHandle:hover:after {
    box-shadow: none !important;
  }

  svg path {
    opacity: 1 !important;
  }

  path:nth-child(2) {
    stroke: #fff !important;
  }

  .current {
    position: absolute;
    top: 100px;
    left: 40px;
    font-size: 16px;
    color: $gray-540;
    margin-top: 8px;
  }

  .show-balance {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    position: absolute;
    top: 62px;
    font-family: "Helvetica-Neue-Bold";
    left: 70px;
  }
}

.balance_header {
  width: 40px;
  height: 40px;
  background: $white;
  border-radius: 50%;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: $black-100;
  justify-content: center;
  cursor: pointer;
}

.balance_section {
  .slider-balance1 {
    padding: 48px 30px;
    background: $black-100;
    border-radius: 16px;
    margin-right: 7px;

    .earning-text {
      font-size: 20px;
    }

    .connect {
      padding: 8px 12px;
      gap: 8px;
      border-radius: 8px;
      background: rgba(250, 250, 250, 0.15);

      .connecttext {
        width: 215px;
        font-size: 14px;
        color: $gray-350;
      }

      .connectpaypal {
        font-size: 18px;
        color: $warning-1;
        font-weight: 700;

        .text-connect {
          margin-left: 10px;
          margin-bottom: 2px;
        }
      }
    }
  }

  .slider-balance2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 48px 30px;
    background: $black-100;
    border-radius: 16px;

    .payout-text {
      font-size: 20px;
    }
  }

  .earning-balance {
    .balance-out {
      background: $black-100;
      border-radius: 12px;
      padding: 12px;
      margin-top: 24px;
      cursor: pointer;
    }

    .balance-text {
      width: 72%;

      .balancetitle {
        font-size: 20px;
        color: $white;
      }

      .balancetime {
        font-size: 14px;
        color: $gray-540;
      }
    }

    .balance-price {
      font-size: 24px;
      color: $gray-150;
    }
  }
}

// Wardrobe
.modal-width {
  width: 37% !important;
}

.outfit_item {
  width: 270px;
  padding: 0.5rem;
}

.wardrobe {
  .sort-button_wardrobe {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 7px;
    gap: 7px;
    height: 28px;
    border-radius: $border-radius-1;
    position: relative;
    span {
      font-size: 16px;
      line-height: 100%;
      color: $gray-150;
    }
  }
}

.room {
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  position: fixed;
  width: 32px;
  height: 98px;
  top: 50%;
  background: #ffffff;
  transform: translateY(-50%);
  border-radius: 8px 0px 0px 8px;
  color: #000000;

  .textroom {
    font-size: 16px;
    font-weight: 500;
    transform: rotate(-90deg);
    cursor: pointer;
  }
}

.modalRoom {
  .textRoom {
    font-size: 28px;
    color: $black-100;
    font-weight: 700;
  }

  .accessible {
    .imgtext {
      color: $black-100;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.imagesmodal {
  .removeimg {
    position: absolute;
    bottom: 0%;
    left: 0px;
  }

  .shirtimage {
    position: relative;
  }

  .addshirtmain {
    position: absolute;
    top: 44%;
    right: 19%;
  }

  .addpantmain {
    position: absolute;
    top: 40%;
    left: 10%;
  }

  .addshoesmain {
    position: absolute;
    top: 35%;
    right: 10%;
  }

  .droptext {
    position: absolute;
    top: 40%;
    left: 35%;
    font-size: 20px;
    text-align: center;
    width: 40%;
    color: $black-100;
    font-weight: 700;
  }
}

.anotherBottomwearimg {
  position: absolute;
  right: -30px;
  width: 200px;
  height: 200px;
  top: 32%;
}

.anotherTopwearimg {
  position: absolute;
  right: -30px;
  width: 200px;
  height: 150px;
}

.shoesImage {
  width: 200px;
  height: 70px;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 350px;
  background: #f7f7f7;
}

.pantImage {
  width: 200px;
  height: 200px;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 150px;
  background: #f7f7f7;
}

.shirtImage {
  width: 200px;
  height: 150px;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  background: #f7f7f7;
}

.add_another_topwear {
  position: absolute;
  left: 215px;
  width: 200px;
  height: 150px;
  top: 0;
}

.add_another_bottomwear {
  position: absolute;
  left: 215px;
  width: 200px;
  height: 200px;
  top: 23%;
}

.accessoriesImage {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.accessoriesBox {
  position: absolute;
  top: 10%;
  left: 0;

  .accessoriesImage {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
}

.cursorPointer {
  cursor: pointer !important;
}

.wardrobe_purchase {
  .your-lists-container {
    .your-list-filter-tabs {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      gap: 12px;
      width: max-content;
      white-space: nowrap;
      height: 32px;
      border: none;
      border-radius: $border-radius-1;
      background-color: $black-300;
      cursor: pointer;

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
        display: flex;
        align-items: center;
        text-align: center;
        color: $white;
      }
      &:hover {
        background-color: $black-400;
      }
    }

    .your-list-filter-active-tabs {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      gap: 12px;
      width: max-content;
      white-space: nowrap;
      height: 32px;
      background: $white;
      border-radius: $border-radius-1;
      cursor: pointer;

      span {
        font-size: 14px;
        font-weight: 700;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.005em;
        color: $black-100;
      }
    }
  }

  .card_main_section {
    align-items: center;

    // Custom scrollbar styling
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #d9d9d9;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $black-300;
      border-radius: 5px;
    }

    .newcard {
      width: auto !important;
      position: relative;
    }

    .carding {
      position: relative;

      .card_section {
        position: absolute;
        bottom: 0;
        left: 12px;
        width: 100%;
        height: 100px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(16, 10, 0, 0) 0.01%,
          rgba(16, 10, 0, 0.75) 100%
        );

        border-radius: 0px 0px 16px 16px;

        .card-text {
          font-size: 20px;
          position: absolute;
          color: $white;
          font-weight: 500;
          bottom: 40px;
          left: 10px;
        }

        .card-description {
          font-weight: 400;
          font-size: 16px;
          position: absolute;
          bottom: 20px;
          left: 10px;
        }
      }
    }

    .carding_section {
      height: 245px;
      padding: 0;

      img {
        width: 100%;
        height: 100%;
        border-radius: 16px;
      }

      .card_text_section {
        left: 0 !important;
      }
    }
  }
}



//wardrobe Modals
.select_accessories {
  .sortText {
    border: 1px solid #a7a7a7;
    border-radius: 12px;
    padding: 0px 10px;
    color: #a7a7a7;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
  }

  .slick-list {
    width: 95%;
    margin-left: 1rem;

    .slick-track {
      height: 210px;

      .slick-slide {
        margin-top: 2rem;
      }
    }
  }

  .or_text {
    color: #a7a7a7;
    font-size: 16px;
    text-align: center;
  }

  .another_img {
    // border: 2px dashed $gray-350;
    // border-radius: 16px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%232323' stroke-width='2' stroke-dasharray='15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 16px;
    width: 200px;
    height: 190px;
    margin: auto;

    .another_img_text {
      font-size: 16px;
      font-weight: 500;
      color: $green-100;
      margin-right: 5px;
    }
  }

  .image_modal {
    width: 48%;
    margin: auto;

    .outfit_image {
      width: 262px;
      height: 236px;
      position: absolute;
      object-fit: contain;
    }

    .another_img_text {
      font-size: 16px;
      color: $green-100;
      margin-right: 5px;
      font-weight: 500;
    }
  }

  .custom-arrow {
    cursor: pointer;

    .prev {
      position: absolute;
      top: 35%;
      left: -10px;
    }

    .next {
      position: absolute;
      top: 35%;
      right: -10px;
    }
  }

  .imageselect {
    background: #000 !important;
    opacity: 0.7 !important;
    top: 0px;
    z-index: 3;
    width: 166px;
    height: 204px;
    border-radius: 16px;

    .check {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }

  .carding {
    width: 166px;
    height: 204px;
    position: relative;

    .card_section {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(16, 10, 0, 0) 0.01%,
        rgba(16, 10, 0, 0.9) 100%
      );
      z-index: 4;

      .card-text {
        font-size: 16px;
        margin-left: 5px;
        color: #ffffff;
      }

      .card-description {
        color: #ffffff;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 13px;
        margin-left: 5px;
      }
    }
  }

  .modal_add_accessories {
    gap: 12px;
    width: 100%;
    margin: auto;
    height: auto;
    max-height: 27em;
    overflow-y: auto;
    overflow-x: hidden;

    // Custom scrollbar styling
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: $gray-350;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $black-300;
      border-radius: 5px;
    }
  }
}

.wardrobe_title {
  font-size: 18px;
  color: $black-100;
  font-family: Helvetica-Neue-Regular;

  .wardrobe_input {
    padding: 5px;
    width: 125px;
    height: 30px;
    border-radius: 5px;
    font-weight: 400;
    color: $black-200;
    border: none;
    background: $white;
  }
}

.edit_wardrobe_room {
  width: 200px;
  height: 100%;

  .accessory-image:nth-child(n + 1) {
    position: relative;
    display: flex;
    flex-direction: column;
    bottom: 0;
    left: -170px;
    margin: 10px 0;
    z-index: 1;
  }
}

.hedit0 {
  height: 0 !important;

  .accessory-image:nth-child(n + 1) {
    bottom: 0% !important;
    left: 0% !important;
  }
}

//Security Settings

.security-settings {
  padding-left: 1%;

  .cannot-change-password-text {
    width: max-content;
    font-family: Helvetica-Neue-Regular !important;
    font-size: 18px;
    line-height: 100%;
    color: #ffffff;
  }

  .heading-txt {
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    color: $white;
    font-family: "Helvetica-Neue-Bold";
  }

  .security-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    width: 415px;
    min-height: 414px;
    max-height: max-content;

    .security-setting-form-label-text {
      font-family: Helvetica-Neue-Regular;
      font-size: 16px;
      line-height: 80%;
      color: $white;
    }

    .security-setting__input {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 12px 16px;
      height: 48px;
      background: $black-100;
      border-radius: $border-radius-1;
      border: none;
      color: $gray-550;
    }

    .login-submit-btn {
      background: $gray-350;
      border-radius: $border-radius-1;
      border: none;
      margin: 0 auto;
      width: 95%;

      span {
        font-size: 16px;
        color: $black-200;
      }
    }

    .forget-password-text {
      font-size: 14px;
      line-height: 100%;
      text-align: center;
      color: $warning-1;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.security-settings-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;
  width: 428px;
  height: 345px;

  .security-settings-card-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 24px;
    width: 428px;
    height: 129px;

    p {
      font-weight: 500;
      font-size: 24px;
      line-height: 100%;
      letter-spacing: 0.005em;
      color: #000000;
    }

    span {
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.005em;
      color: #7a7a7a;
    }
  }

  .resend-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
      font-family: Helvetica-Neue-Regular !important;
      font-size: 16px;
      line-height: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      text-decoration-line: underline;
      color: #27755e;
      cursor: pointer;
    }
  }
}

//notification-settings

.notification-settings {
  padding-left: 1%;

  .heading-txt {
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    color: $white;
    font-family: "Helvetica-Neue-Bold";
  }

  .notification-settings-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 64px;

    .cheeky-updates-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;
      width: 100%;
      height: 217px;

      .cheeky-updates-heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 45px 0px 0px;
        width: 100%;
        height: 18px;

        p {
          font-weight: 700;
          font-size: 18px;
          line-height: 100%;
          color: $white;
          font-family: "Helvetica-Neue-Bold";
        }

        .cheeky-updates-heading-icon-container {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          gap: 43px;
          width: 188px;
          height: 18px;

          .email {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 12px;
            width: 74px;
            height: 18px;

            p {
              font-size: 16px;
              line-height: 100%;
              letter-spacing: 0.08px;
              color: $gray-350;
            }
          }

          .push {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 12px;
            width: 74px;
            height: 18px;

            p {
              font-size: 16px;
              line-height: 100%;
              text-transform: uppercase;
              letter-spacing: 0.08px;
              color: $gray-350;
            }
          }
        }
      }
    }

    .toogle-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      width: 100%;

      .toogle-card-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 24px 50px 24px 24px;
        width: 100%;
        background: $black-100;
        border-radius: 8px;

        .toogle-card-top-description {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 8px;
          max-width: 100%;

          @media screen and (max-width: 1080px) {
            max-width: 50%;
          }

          p {
            font-size: 16px;
            line-height: 100%;
            display: flex;
            align-items: center;
            color: $white;
            font-weight: 500;
          }

          span {
            font-size: 16px;
            line-height: 120%;
            color: $gray-540;
            display: inline-block;
            max-width: 100%;

            @media screen and (max-width: 758px) {
              max-width: 100%;
            }

            @media screen and (max-width: 560px) {
              display: none;
            }
          }
        }

        .toogle-card-top-toogles {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0px;
          height: 32px;
          gap: 76px;
        }
      }

      .toogle-card-top1 {
        @extend .toogle-card-top;
      }
    }
  }
}

//referral-settings

.referral-settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 48px;
  padding-left: 1%;

  .heading-txt {
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    color: $white;
    font-family: "Helvetica-Neue-Bold";
  }

  .referral-settings-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    width: 699px;

    .payment-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;
      width: 300px;

      .payment-heading {
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        color: $white;
        font-family: "Helvetica-Neue-Bold";
      }

      .payment-details {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 48px;
        width: 300px;
        // height: 108px;

        .total-earned {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 16px;
          width: 126px;
          height: 108px;

          p {
            font-size: 16px;
            line-height: 100%;
            color: $white;
            font-weight: 500;
          }

          .total-earned-count {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 24px;
            gap: 10px;
            min-width: 126px;
            background: $white;
            border-radius: 12px;

            p {
              font-weight: 700;
              font-size: 28px;
              line-height: 100%;
              color: $black-100;
              font-family: "Helvetica-Neue-Bold";
            }
          }
        }

        .upcomming {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 16px;

          p {
            font-size: 16px;
            line-height: 100%;
            color: $white;
            font-weight: 500;
          }

          .upcomming-count {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 24px;
            gap: 10px;
            min-width: 126px;
            background: $white;
            border-radius: 12px;

            p {
              font-weight: 700;
              font-size: 28px;
              line-height: 100%;
              color: $black-100;
              font-family: "Helvetica-Neue-Bold";
            }
          }
        }
      }

      .affiliate-link {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
        width: 50vw;

        .affiliate-link-heading {
          font-weight: 700;
          font-size: 20px;
          line-height: 100%;
          color: $white;
          font-family: "Helvetica-Neue-Bold";
        }

        .affiliate-link-input {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 12px 16px;
          background-color: $black-100;
          border: none;
          color: $gray-350;
        }
      }

      .affiliate-link-text {
        font-size: 18px;
        line-height: 100%;
        color: $danger;
        width: 50vw;
      }
    }
    .create-bank-account-btn {
      background: $gray-350;
      border-radius: $border-radius-1;
      border: none;

      span {
        font-family: Helvetica-Neue-Medium;
        font-size: 16px;
        color: $gray-550;
      }
    }
    .create-bank-account-btn-filled {
      background: $white;
      border-radius: $border-radius-1;
      box-shadow: unset;
      border: none;

      span {
        font-family: Helvetica-Neue-Medium;
        font-size: 16px;
        color: $black-100;
        font-weight: 500;
      }
    }
  }

  .active-members-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    .active-members-heading {
      font-weight: 500;
      font-size: 20px;
      line-height: 100%;
      color: $white;
    }

    .active-members-count {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 24px;
      gap: 10px;
      min-width: 126px;
      background: $white;
      border-radius: 12px;

      p {
        font-weight: 700;
        font-size: 28px;
        line-height: 100%;
        color: $black-100;
        font-family: "Helvetica-Neue-Bold";
      }
    }
  }

  .share-link-input {
    font-family: "Helvetica-Neue-Regular";
    font-size: 16px;
    line-height: 80%;
    color: #d9d9d9;
  }

  .input-with-icon {
    position: relative;

    img {
      position: absolute;
      right: 3%;
      top: 55%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}

//Add bank Account Modal

p {
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0.005em;
  color: $black-100;
}

.close-button {
  display: inline-flex;
  justify-content: flex-end;
  width: 12px;
  height: 12px;
}

.add-source-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 8px 0px;
  gap: 8px;
  border-bottom: 2px solid #f7f7f7;

  .add-bank-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 384px;

    .add-bank {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 12px;
      gap: 47px;
      width: 384px;
      height: 48px;
      border: 2px solid #f7f7f7;
      border-radius: 8px;

      .source {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 8px;
        width: max-content;
        height: 24px;

        img {
          width: 24px;
          height: 24px;
        }

        p {
          font-size: 20px;
          line-height: 100%;
          letter-spacing: 0.005em;
          color: $black-100;
        }
      }
    }

    .add-bank-text {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 4px;
      width: 384px;
      height: 42px;
      font-family: "Helvetica-Neue-Medium";
      font-size: 14px;
      line-height: 150%;
      color: #7a7a7a;

      span {
        color: #27755e;
        cursor: pointer;
      }
    }
  }
}

.sticky-drawer {
  position: absolute !important;
  width: 34px;
  height: 40px;
  right: 0px;
  top: 75px;
  z-index: 1;
  background: #e5b15e;
  box-shadow: 0px 2px 0px #5d412d;
  border-radius: 8px 0px 0px 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 22px;
    height: 22px;
  }
}

//Your Lists
.your-lists {
  .explore-heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 100%;
    color: $white;
  }
}

.your-lists-container {
  position: relative;

  //sort Menu
  .sort-button {
    position: relative;

    &.active {
      .sort-menu {
        display: block;
      }
    }
  }

  .your-list-filter-tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 12px;
    height: 32px;
    // background-color: $black-300;
    border: 1px solid $white;
    border-radius: $border-radius-1;
    cursor: pointer;

    span {
      font-size: 14px;
      line-height: 125%;
      display: flex;
      align-items: center;
      text-align: center;
      color: $white;
    }
    &:hover {
      background-color: $black-400;
    }
  }

  .your-list-filter-active-tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 12px;
    height: 32px;
    background: $white;
    border-radius: $border-radius-1;
    cursor: pointer;

    span {
      font-size: 14px;
      font-weight: 700;
      line-height: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.005em;
      color: $black-100;
    }
  }

  .sort-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 7px;
    height: 28px;
    border-radius: $border-radius-1;

    span {
      font-size: 16px;
      line-height: 100%;
      color: $gray-150;
    }

    .sort-menu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 16px 8px;
      gap: 10px;
      position: absolute;
      width: 176px;
      height: 208px;
      right: 2%;
      bottom: 50%;
      background: #000000;
      // box-shadow: 0px 5px 25px 2px rgba(255, 255, 255, 0.1);
      border-radius: 8px;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          font-size: 14px;
          line-height: 125%;
          color: #f7f7f7;
          border-bottom: 0.75px solid #7a7a7a;
          padding: 10px;
          cursor: pointer;

          &:last-child {
            border: none;
          }

          &:hover {
            color: #e5b15e;
          }
        }
      }
    }
  }

  //list cards styling

  .wishlist-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: $white;
    backdrop-filter: blur(2.5px);
    // border-radius: 16px;
    width: 100%;
    height: 307.5px;

    &:hover {
      filter: brightness(0.5);
    }

    .wishlist-card-items {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: 12px;
      width: 89px;
      height: 96px;

      p {
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
        letter-spacing: 0.005em;
        color: $black-100;
      }
    }
  }

  .list-card {
    // padding: 0px 0px 12px;
    height: 314px;
    // border: 1px solid $gray-520;
    // border-radius: 16px;
    cursor: pointer;

    .list-card-options {
      display: none;
    }

    &:hover {
      .list-card-options {
        display: flex;
        position: absolute;
        width: 24px;
        height: 24px;
        right: 28px;
        top: 22px;
        transform: rotate(-90deg);
        z-index: 100;
      }
      .image-full {
        filter: brightness(0.5);
      }
    }

    .list-card-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0px;

      .image-full {
        height: 256px;
        // border-radius: 15px 15px 15px 15px;
        object-fit: contain;
        background-color: $white;
      }

      .list-card-text-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 24px 12px 12px;
        gap: 12px;
        width: 262px;
        height: 65px;

        .list-card-text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 8px;
          width: 100%;

          .list-card-text-title {
            font-size: 19px;
            line-height: 1.06;
            color: $white;
            font-weight: 500;
          }

          span {
            font-size: 16px;
            line-height: 100%;
            color: $gray-350;
          }
        }

        .heart-icon {
          width: 20px;
          height: 17px;
          border-radius: 0;
        }
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

//orders
.allorders {
  .your-list-filter-tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 12px;
    width: max-content;
    white-space: nowrap;
    height: 32px;
    background-color: $black-300;
    border: none;
    border-radius: $border-radius-1;
    cursor: pointer;

    span {
      font-size: 14px;
      line-height: 125%;
      display: flex;
      align-items: center;
      text-align: center;
      color: $white;
    }
    &:hover {
      background-color: $black-400;
    }
  }

  .your-list-filter-active-tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 12px;
    width: max-content;
    white-space: nowrap;
    height: 32px;
    background: $white;
    border-radius: $border-radius-1;
    cursor: pointer;

    span {
      font-size: 14px;
      font-weight: 700;
      line-height: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.005em;
      color: $black-100;
    }
  }
}

.transitorder {
  .all_order {
    .text-order {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: $white;
    }

    .text-border {
      border: 1px solid $gray-720;
      width: 91%;
      margin-left: 27px;
    }
  }

  .text_section {
    gap: 12px;
    width: 100%;
    margin: auto;
    height: auto;
    max-height: 20em;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 24px 0px 24px 24px;
    margin-top: 20px;

    .infinite-scroll-component__outerdiv {
      width: 100%;
    }

    // Custom scrollbar styling
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: $black-300;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-350;
      border-radius: 5px;
    }

    .text_section_order {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 12px;
      gap: 15px;
      max-width: 670px;
      width: 100%;
      border: 0.75px solid $gray-550;
      border-radius: 16px;

      .image-order-card {
        .order-product {
          .product-image {
            width: 12%;
            height: 80px;
            img {
              width: 100%;
              height: 100%;
              background-color: $gray-350;
              border-radius: 8px;
            }
          }
          .product-detail {
            margin-left: 12px;
            width: 68%;
            .product-name {
              font-size: 14px;
              color: $gray-720;
              font-family: "Helvetica-Neue-Regular";
            }
            .product-status {
              font-size: 20px;
              color: $white;
              font-family: "Helvetica-Neue-Medium";
              font-weight: 700;
            }

            .product-date {
              font-size: 14px;
              color: $gray-720;
              font-family: "Helvetica-Neue-Regular";
            }
          }
        }

        .order-btn {
          text-align: center;

          .track-btn {
            border: 1px solid $green-200;
            border-radius: $border-radius-1;
            font-family: "Helvetica-Neue-Regular";
            font-size: 16px;
            display: flex;
            align-items: center;
            color: $green-200;
            justify-content: center;
            width: 110px;
            height: 32px;
            margin: auto;
            cursor: pointer;
          }
        }
      }

      .image-order-text1 {
        font-size: 12px;
        color: #e5b15e;
      }

      .image-order-date {
        font-size: 12px;
        color: #ffffff;
      }
    }
  }
}

.add-package-container {
  .ortext {
    font-size: 16px;
    color: $gray-550;
  }

  .trackmail {
    .mail-text {
      font-weight: 500;
      font-size: 18px;
      color: $gray-720;
    }

    .trackmail-guest {
      border: 2px dotted $gray-720;
      font-family: "Inter";
      font-weight: 700;
      font-size: 16px;
      color: $black-200;
      margin: 0.75rem auto;
      text-align: center;
    }
  }

  .checkimg {
    width: 27px;
    height: 27px;
    background: $green-200;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .check_img {
    width: 27px;
    height: 27px;
    background: $gray-350;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .step_bar_height {
    height: 75px;
    border-left: 2px solid $green-200;
    width: 94%;
    margin: auto;
  }

  .step_bar_height-dashed {
    height: 75px;
    border-left: 2px dashed $gray-350;
    width: 94%;
    margin: auto;
  }

  .step1_text {
    font-size: 16px;
    color: $gray-720;
    margin-left: 13px;
    font-family: "Helvetica-Neue-Medium";
    font-weight: 500;
  }

  .step1_time {
    font-size: 12px;
    color: $gray-720;
    margin-left: 14px;
    font-family: "Helvetica-Neue-Regular";
    font-weight: 400;
  }
}

//card content

.share-link-content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  .social-icon {
    width: 24px;
    height: 24px;
  }

  .social-icon-text {
    font-family: "Helvetica-Neue-Regular";
    font-size: 16px;
    font-weight: 400;
    color: $black-300;
  }
}

//share link modal
.share-link-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;

  .header-text {
    p {
      font-family: "Helvetica-Neue-Bold";
      font-weight: 700;
      font-size: 28px;
      line-height: 110%;
      letter-spacing: 0.005em;
      color: $black-100;
    }
  }
}

// YOUR_LIST_ITEMS

.list-info-wrapper {
  width: 100%;
  display: flex;

  .list-image-wrapper {
    width: 137px;
    height: 137px;
    background: $white;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
  }

  .images {   
    display: grid;

      grid-template-columns: 1fr 1fr;
      grid-template-rows: 50% 50%;
      grid-auto-rows: 50%;
      width: 100%;
      height: 100%;
    
  }

  .multi-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .list-header {
    font-size: 50px;
    font-weight: 600;
    color: $white;
    line-height: 54px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
  }
  .list-description {  
    font-size: 18px;
    color: $gray-250;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
  }
  .list-author {
    font-size: 20px;
    color: $white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
  }

}

.your-list-items {
  .nav-header {
    font-size: 14px;
    line-height: 125%;
    color: #d9d9d9;

    p {
      font-family: Helvetica-Neue-Regular !important;
    }
  }

  .edit-name-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 7px;
    width: max-content;
    height: 24px;

    p {
      height: 24px;
      font-weight: 700;
      font-size: 24px;
      line-height: 100%;
      color: #ffffff;
    }

    .edit-name-input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 4px;
      gap: 10px;
      width: 121px;
      height: 34px;
      background: #000000;
      border-radius: 4px;

      .edit-name {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px 0px 2px 0px;
        gap: 10px;
        width: 114px;
        height: 26px;
        border: none;
        border-radius: 0px;
        background: #000000;
        border-bottom: 1px solid #e5b15e;
        font-family: Helvetica-Neue-Medium !important;
        font-weight: 400;
        font-size: 24px;
        color: #ffffff;
      }
    }

    .edit-name-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 8px;
      width: 56px;
      height: 24px;

      img {
        cursor: pointer;
      }
    }
  }

  .remove-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 24px;
    width: 136px;
    height: 16px;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 24px;
      width: 136px;
      height: 16px;

      span {
        height: 16px;
        font-family: Helvetica-Neue-Regular !important;
        font-size: 16px;
        line-height: 100%;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }

  .list-item-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    width: max-content;
    // height: 24px;

    p {
      width: max-content;
      // height: 24px;
      font-family: "Helvetica-Neue-Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 100%;
      color: $white;
    }

    span {
      width: max-content;
      // height: 13px;
      font-size: 18px;
      line-height: 100%;
      color: $white;
    }
  }

  .list-item-heading-icons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: max-content;
    height: 24px;

    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    .options-dialog {
      transform: rotate(-90deg);
    }
  }

  .list-item-card-container {
    border-radius: 16px;

    .card-maindiv {
      width: 100%;
      height: 100%;
      max-height: 320px;
      cursor: pointer;

      .list-item-card-text-container {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0;
        position: absolute;
        left: 0;
        bottom: 0;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(16, 10, 0, 0) 0.01%,
          #100a00 100%
        );

        border-radius: 0 0 13px 13px;
        gap: 8px;

        p {
          height: 21px;
          font-size: 20px;
          line-height: 100%;
          letter-spacing: 0.005em;
          color: #ffffff;
        }

        span {
          // height: 17px;
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          letter-spacing: 0.005em;
          color: #ffffff;
        }

        .price {
          height: 21px;
          font-family: Helvetica-Neue-Medium !important;
          font-size: 20px;
          line-height: 100%;
          letter-spacing: 0.005em;
          color: #ffffff;
        }
      }

      .home-card-options {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 32px;
        top: 27px;
        cursor: pointer;
        display: none;
      }

      .list-item-card:hover .home-card-options {
        display: block !important;
      }

      .home-card-text-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0px;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(16, 10, 0, 0) 0.01%,
          #100a00 100%
        );
        border-radius: 0 0 13px 13px;
      }

      .list-item-card:hover svg {
        display: block;
      }

      .list-item-card:hover .list-card-content > img {
        filter: brightness(0.5);
      }
      .list-item-card:hover .list-card-content > .image {
        filter: brightness(0.5);
      }
    }
  }
}

//ItemDescriptionModal STYLES
.card-content {
  .card-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 24px !important;
    padding: 5px !important;
    gap: 8px;
    border-bottom: 1px solid #f7f7f7 !important;

    .modal-close-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px;
      gap: 12px;
      height: 35px;
      border-radius: 50px;
      border: none;
      background-color: transparent;
    }

    p {
      // height: 24px;
      font-family: Helvetica-Neue-Medium !important;
      font-weight: 700;
      font-size: 24px;
      line-height: 160%;
      letter-spacing: 0.005em;
      color: #000000;
    }
  }

  .card-item-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 35px;

    .card-content-top {
      display: flex;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;

      .cart-top-text-container {
        display: flex;
        width: 55%;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        padding: 0px;
        gap: 20px;

        .item-description-container {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 0px;
          gap: 24px;
          width: 100%;

          span {
            font-family: "Helvetica-Neue-Medium";
            font-size: 16px;
            line-height: 100%;
            color: $black-100;
          }
        }

        .icon-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          padding: 0px;
          gap: 12px;
          width: max-content;

          span {
            cursor: pointer;
          }

          .heart-icon {
            width: 20px;
            height: 17px;
          }
        }
      }
      .image-container {
        width: 45%;
        height: 350px;
        box-sizing: border-box;
        background: $gray-120;
        border-radius: 16px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .card-content-bottom {
      .show-all {
        color: $black-100;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .add-to-tags {
      display: flex;
      flex-direction: row;
      gap: 12px;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      .slick-slider {
        width: 100%;
      }
      .slick-list {
        overflow: hidden;
      }
      .slick-track {
        display: flex;
        justify-content: start;
        align-items: center;
        margin: 0;
      }
      .slick-slide {
        margin-right: 0.5rem;
      }

      .tags {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 16px;
        background-color: transparent;
        border: 1px solid $black-100;
        cursor: pointer;

        &.active-tags {
          border: none;
          span {
            font-family: "Helvetica-Neue-Medium";
          }
        }

        &.similar {
          padding: 2px 4px;
          border: 0;
        }

        span {
          font-family: Helvetica-Neue-Regular;
          font-size: 14px;
          line-height: 125%;
          display: flex;
          align-items: center;
          text-align: center;
          color: $black-100;
        }
      }
    }
  }
}

// Delete Modal
.delete-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  gap: 24px;
  border-radius: 32px;

  p {
    height: max-content;
    font-family: Helvetica-Neue-Regular !important;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #7a7a7a;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 260px;
    height: 74px;

    button {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      gap: 12px;
      width: 260px;
      height: 32px;
      border: 1px solid #ff5449;
      border-radius: 8px;
      background-color: #ffffff;

      span {
        height: 16px;
        font-family: Helvetica-Neue-Regular !important;
        font-size: 16px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ff5449;
        cursor: pointer;
      }
    }

    span {
      font-family: Helvetica-Neue-Regular !important;
      font-size: 14px;
      line-height: 125%;
      display: flex;
      align-items: center;
      text-align: center;
      color: #7a7a7a;
      cursor: pointer;
    }
  }
}

// WishLIST

.wishlist {
  .wishlist-heading {
    font-size: 32px;
    line-height: 100%;
    color: $white;
    font-weight: 700;
    font-family: "Helvetica-Neue-Bold";
    margin-right: auto;
  }

  .ml-auto {
    margin-left: auto;
  }
  
  .suggested-tabs-container {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow: hidden;

    &.wardrobe {
      overflow: visible;
      height: auto;
    }

    p {
      font-size: 16px;
      line-height: 100%;
      color: $gray-550;
    }

    .suggested-tabs {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      gap: 12px;
      height: 28px;
      background-color: $black-300;
      border: none;
      border-radius: $border-radius-1;
      cursor: pointer;

      span {
        font-family: "Helvetica-Neue-Regular";
        font-size: 14px;
        line-height: 125%;
        display: flex;
        align-items: center;
        text-align: center;
        color: $white;
      }

      &.popup-modal {
        border: 1px solid $gray-550;
        color: $gray-550;
        background-color: $white;

        span {
          color: $gray-550;
        }
      }

      &:hover {
        background-color: $black-400;
      }
    }

    .suggested-tabs-active {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      gap: 12px;
      height: 28px;
      background: $white;
      border-radius: $border-radius-1;
      cursor: pointer;

      span {
        font-family: "Helvetica-Neue-Bold";
        font-size: 14px;
        font-weight: 700;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.005em;
        color: $black-100;
      }
      &.popup-modal {
        background-color: $black-300;
        border: none;

        span {
          color: $gray-520;
        }
      }
    }
  }

  .dropdowns-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
  }

  // //sort button
  // .sort-button-container {

  //   .sort-button {
  //     display: flex;
  //     align-items: center;
  //     border: 1px solid #f2f2f2;
  //     background-color: #f2f2f2;
  //     color: #7a7a7a;
  //     border-radius: 8px;
  //     cursor: pointer;
  //     font-family: "Helvetica-Neue-Regular ";
  //     font-size: 16px;
  //     line-height: 100%;
  //     padding: 6px 12px;

  //     .sort-icon {
  //       width: 1rem;
  //       margin-left: 0.5rem;
  //     }

  //     span {
  //       margin-right: 10px;
  //     }
  //   }

  //   .sort-menu {
  //     position: absolute;
  //     top: 100%;
  //     left: 0;
  //     display: flex;
  //     flex-direction: column;
  //     align-items: flex-start;
  //     width: 177.09px;
  //     background: #ffffff;
  //     // box-shadow: 0px 4px 9px #d9d9d9;
  //     border-radius: 8px;

  //     ul {
  //       list-style: none;
  //       padding: 0;
  //       margin: 0;

  //       li {
  //         font-family: Helvetica-Neue-Regular !important;
  //         font-size: 16px;
  //         line-height: 80%;
  //         color: #7a7a7a;
  //         padding: 0.8rem;
  //         cursor: pointer;

  //         &:hover {
  //           background-color: #ddd;
  //         }

  //         &.selected {
  //           background-color: #333;
  //           color: #fff;
  //         }
  //       }
  //     }
  //   }
  // }
}

  .toggle-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50px;
    height: 23px;
    border-radius: 13px;
    background-color: $green-150;
    border: 1px solid $black-100;
    cursor: pointer;
  }

  .toggle-switch.off {
    background-color: $gray-520;
  }

  .toggle-switch-circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: $white;

    transition: transform 0.2s ease-in-out;
  }

  .toggle-switch-circle.on {
    transform: translateX(27px);
  }

  .toggle-switch-circle.off {
    transform: translateX(-1px);
  }

// Deactivate Account-Modal

.deactivate-account-close-button {
  display: flex;
  justify-content: end;
  align-items: end;
}

.deactivate-account-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  p {
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0.005em;
    color: $black-100;
  }
}

.deactivate-account-card-text {
  font-size: 16px;
  line-height: 125%;
  color: $gray-520;
}

.delete-account-card-text {
  font-size: 14px;
  line-height: 150%;
  color: $gray-720;
}

.card {
  .delete-account-card-btn {
    background: $danger-2;
    border-radius: $border-radius-1;
    border: none;

    span {
      font-family: Helvetica-Neue-Medium;
      font-size: 16px;
      color: $gray-750;
    }
  }

  .delete-account-card-btn-filled {
    background: $danger;
    border-radius: $border-radius-1;
    border: none;

    span {
      font-family: Helvetica-Neue-Medium;
      font-size: 16px;
      color: $gray-750;
      color: $white;
    }
  }
}

.create-new-list {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 16px;

  .title-and-public-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 16px;
    width: 415px;
    height: 24px;
  }
}

.isPublic-toggle {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: end;
  gap: 8px;
  width: max-content;

  p {
    font-family: Helvetica-Neue-Regular;
    font-size: 16px;
    line-height: 125%;
    color: $gray-720;

    &.description-modal {
      color: $black-100;
    }
  }

}

.suggested-category-items-container {
  .slick-track {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 0;
  }
  .slick-slider {
    width: 100%;
  }
  .slick-list {
    overflow: hidden;
  }
  .slick-slide {
    margin-right: 0.5rem;
  }

  .suggested-tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    background-color: transparent;
    border: 1px solid $black-100;
    cursor: pointer;

    span {
      font-family: Helvetica-Neue-Regular;
      font-size: 14px;
      line-height: 125%;
      display: flex;
      align-items: center;
      text-align: center;
      color: $black-100;
    }
  }
}

// EXPLORE

.explore {

  .explore-heading {
    font-family: "Helvetica-Neue-Bold";
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    color: $white;
  }

  .explore-container {
    position: relative;

    .filter-section {
      display: flex;
      gap: 12px;
      align-items: center;
      margin-bottom: 20px;
    
      .filter-dropdown {
        position: relative;
      }
    
      .filter-button {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 4px;
        color: #ffffff;
        cursor: pointer;
        font-size: 14px;
        position: relative;
    
        &.active {
          background-color: #ffffff;
          color: #000000;
        }
    
        .arrow-down {
          margin-left: 8px;
          font-size: 10px;
        }
      }

      .on-sale-filter {
        .filter-button {
          cursor: pointer;
          background-color: transparent !important;
          color: #ffffff !important;
          display: flex;
          align-items: center;
          border: 1px solid #ffffff;
          border-radius: 4px;
  
          input[type="checkbox"] {
            cursor: pointer;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            min-width: 16px !important;
            min-height: 16px !important;
            max-width: 16px !important;
            max-height: 16px !important;
            background-color: transparent;
            border: 1px solid #ffffff;
            border-radius: 2px;
            outline: none;
            transition: background-color 0.3s;
            margin: 0 8px 0 0;
            padding: 0;
            position: relative;
            flex-shrink: 0;
            box-sizing: border-box;
  
            &:checked {
              background-color: #ffffff;
              position: relative;
  
              &::after {
                content: '✓';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #000000;
                font-size: 12px;
              }
            }
          }
        }
      }
    
      .dropdown-content {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 100%;
        left: 0;
        background-color: #ffffff;
        border-radius: 4px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        z-index: 10;
        min-width: 150px;
        align-items: center;
      }
    
      .dropdown-item {
        padding: 8px 12px;
        color: #000000;
        cursor: pointer;
    
        &:hover {
          background-color: #f0f0f0;
        }
      }

      .dropdown-range {
        display: flex;
        padding: 8px 12px;
        color: #000000;
        cursor: default;
        justify-content: center;
        align-items: center;
      }

      .dropdown-range-input-container {
        padding: 20px;
      }

      .dropdown-range-input {
        padding: 20px;
        margin-right: 60px;
        width: 100%;
        border-radius: 0%;
      }

      .dropdown-range-submit {
        padding: 10px;
        width: 30%;
        border-radius: 40px;
        background-color: #000;
        color: #FFF;
        margin-bottom: 10px;
        transition: 200ms;

        &:hover {
          background-color: #333;
        }
      }
    }

    .selected-filters {
      display: flex;
    }
    
    .selected-filter {
      background-color: #000; 
      border: 1px solid #dcdcdc; 
      padding: 8px 12px; 
      border-radius: 5px; 
      color: #FFF; 
      font-size: 14px;
      font-weight: bold;
      display: flex; 
      align-items: center; 
      gap: 5px; 
      cursor: pointer; 
      margin-right: 10px;
    }

    .selected-filter:hover {
      background-color: #e0e0e0; 
      border-color: #c0c0c0;
    }

    .selected-filter:focus {
      outline: none; 
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6); 
    }

    .selected-filter span {
      margin-left: 8px; 
    }

    .selected-filter span.close-icon {
      font-size: 12px; 
      color: #999999; 
    }

    .cards-container {
      .explore-card {
        width: 100%;
        height: 346px;
        border-radius: 16px;
        cursor: pointer;
        position: relative;
        padding: 0 !important;

        p {
          position: absolute;
          width: 95%;
          text-align: center;
          color: $white;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
          left: 50%;
          transform: translate(-50%);
          bottom: 15%;
          font-family: "Helvetica-Neue-Medium";
          font-weight: 700;
          line-height: 120%;
        }
      }

      .explore-all-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 262px;
        height: 312px;
        background: $warning-1;
        border-radius: 16px;
        cursor: pointer;

        .explore-all-card-items {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0px;
          gap: 12px;
          width: max-content;
          height: 96px;

          p {
            font-family: "Helvetica-Neue-Medium";
            font-weight: 700;
            font-size: 28px;
            line-height: 100%;
            display: flex;
            align-items: center;
            color: $black-200;
          }
        }
      }
    }
  }

  .coupon-container {
    position: relative;

    .coupon-cards-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 14px;

      .coupon-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px 24px;
        gap: 42px;
        width: 254px;
        height: 275px;
        background: #ffffff;
        border-radius: 16px;
        cursor: pointer;

        .card-details-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0px;
          gap: 32px;
          width: max-content;
          height: 100%;

          p {
            font-family: "Helvetica-Neue-Regular";
            font-size: 14px;
            line-height: 125%;
            color: #323232;
          }

          .card-brand-details {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0px;
            gap: 12px;
            width: max-content;
            height: 100%;

            p {
              font-family: "Helvetica-Neue-Medium";
              font-size: 16px;
              line-height: 100%;
              letter-spacing: 0.005em;
              color: #323232;
            }

            .card-brand-image {
              width: 48px;
            }
          }
        }
      }
    }
  }
}

// Explore-list {
.explore-list {
  padding-left: 1%;

  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0px;
    height: 24px;

    .show {
      color: #fcdba7;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      font-family: Helvetica-Neue-Medium;
      cursor: pointer;
    }
  }

  .explore-heading {
    font-family: "Helvetica-Neue-Bold";
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    color: $white;
  }

  .explore-list-card-container {
    .list-card {
      height: 230px;
      max-width: 220px;
      border: 1px solid #c4c4c4 !important;
      border-radius: 16px;
      cursor: pointer;
      padding: 0;

      .list-card-content:hover > img {
        filter: brightness(0.5);
      }

      .list-card-content {
        padding: 0px;
        width: 100%;

        .image-full {
          width: 100%;
          height: 237px;
          // border-radius: 15px 15px 0px 0px;
          object-fit: cover;
        }
      }

      .list-card-profile {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px;
        gap: 12px;
        width: 100%;

        .list-card-profile-text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 4px;
          width: max-content;

          p {
            font-family: "Helvetica-Neue-Medium";
            font-size: 16px;
            line-height: 19px;
            color: $white;
            font-weight: 500;
          }

          span {
            font-family: "Helvetica-Neue-Medium";
            font-size: 16px;
            line-height: 100%;
            color: $gray-350;
          }
        }
      }

      .list-card-button {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding: 12px 89px;
        gap: 10px;
        width: 100%;
        height: 41px;
        border-top: 1.5px solid $gray-720;
        border-radius: 0px 0px 16px 16px;

        &.brand {
          &:hover {
            background-color: unset;
          }
        }
        &:hover {
          background-color: $gray-720;
        }

        span {
          font-family: "Helvetica-Neue-Regular";
          font-size: 16px;
          line-height: 80%;
          color: $gray-350;
        }
      }
    }

    .outfit-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 17px;
      max-width: 1099px;
      height: 314px;

      .outfit {
        padding-left: 1%;
      }

      .home-card-options {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 45px;
        top: 27px;
        cursor: pointer;
        display: none;
      }

      .home-card:hover .home-card-options {
        display: block !important;
      }

      .home-card-text-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0px;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(16, 10, 0, 0) 0.01%,
          #100a00 100%
        );
        border-radius: 0 0 13px 13px;
      }
    }
  }

  .card-text-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: max-content;
    height: 74px;
    padding-left: 14px;
    padding-bottom: 25px;
    cursor: pointer;

    p {
      font-family: "Helvetica-Neue-Medium";
      font-size: 20px;
      line-height: 100%;
      color: #ffffff;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    }

    span {
      font-family: "Helvetica-Neue-Regular";
      font-size: 16px;
      line-height: 80%;
      color: #d9d9d9;
    }
  }

  .card-icon-div {
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 10px;
    padding-right: 14px;

    .card-icon-img {
      cursor: pointer;
    }
  }

  .home-card:hover {
    .card-icon-div {
      display: flex;
    }
  }

  .people-you-follow-card-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 21px;
    width: 100%;
    height: 304px;

    .list-card {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      width: 262px;
      height: 304px;
      // border: 1px solid $gray-520;
      border-radius: 16px;
      cursor: pointer;

      .list-card-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0px;
        width: 262px;

        .image-full {
          height: 237px;
          // border-radius: 15px 15px 0px 0px;
          object-fit: contain;
          background-color: $white;
        }
      }

      .list-card-profile {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px;
        gap: 12px;
        width: 262px;
        height: 66px;

        .list-card-profile-text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 4px;
          width: max-content;
          height: 39px;

          p {
            font-family: "Helvetica-Neue-Medium";
            font-size: 16px;
            line-height: 19px;
            color: $white;
            font-weight: 500;
          }

          span {
            font-family: "Helvetica-Neue-Medium";
            font-size: 16px;
            line-height: 100%;
            color: $gray-350;
          }
        }
      }
    }
  }
}

//AddToCArt Modal

.add-to-cart {
  .add-to-cart-profile-name {
    font-family: "Helvetica-Neue-Medium";
    color: $black-100;
    font-size: 18px;
    line-height: 180%;
    font-weight: 500;
  }

  .checkbox-container {
    position: relative;

    input[type="checkbox"]:checked {
      background-color: $white;
    }

    input[type="checkbox"]:checked::before {
      content: "\2713";
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      text-align: center;
      line-height: 0%;
      font-size: 20px;
      color: #fff;
    }
  }

  .card-header {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 24px !important;
    padding: 5px !important;
    gap: 8px;
    border-bottom: 1px solid #f7f7f7 !important;
  }

  .add-to-cart-item-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 0px;
    gap: 16px;

    .add-to-cart-item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 10px;
      width: 670px;

      .add-to-cart-item-image {
        width: 30%;
        height: 190px;
        background-color: $gray-350;
        border-radius: 16px;
      }

      .add-to-cart-item-content-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0px;
        gap: 33px;
        width: 70%;

        .add-to-cart-item-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 24px;
          width: 80%;

          .brand-name {
            font-family: "Helvetica-Neue-Bold";
            font-size: 24px;
            line-height: 100%;
            font-weight: 700;
            color: $black-100;
            text-transform: capitalize;
            font-weight: 600;
            @include line-clamp(2);
          }

          .add-to-cart-item-description {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 12px;

            .item-name {
              font-family: "Helvetica-Neue-Bold";
              font-size: 20px;
              line-height: 100%;
              color: $black-100;
              font-weight: 700;
              @include line-clamp(2);
            }

            .item-desc {
              font-family: "Helvetica-Neue-Regular";
              font-size: 14px;
              line-height: 125%;
              color: $gray-720;
              @include line-clamp(3);
            }
          }

          .item-price {
            font-family: "Helvetica-Neue-Medium";
            font-size: 18px;
            line-height: 100%;
            font-weight: 500;
            color: $black-100;
          }
        }

        .icon-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          padding: 0px;
          gap: 12px;
          width: max-content;
          color: #7a7a7a;

          span {
            cursor: pointer;
          }

          .heart-icon {
            width: 20px;
            height: 17px;
          }
        }
      }
    }
  }

  .add-to-cart-modal-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 32px;
    height: 48px;

    .btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      width: 415px;
      height: 48px;
      background: $black-100;
      box-shadow: unset;
      border-radius: $border-radius-1;
      flex: none;
      order: 0;
      flex-grow: 0;
      &.disabled {
        background: $gray-350;
        border-radius: $border-radius-1 !important;
        border: none;

        span {
          font-family: Helvetica-Neue-Medium;
          color: $gray-550;
        }
      }

      span {
        width: max-content;
        height: 16px;
        font-family: "Helvetica-Neue-Medium";
        font-size: 16px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.005em;
        color: $white;
      }
    }
  }
}

.move-to-cart-items-container {
  max-height: 65vh;
  overflow-y: auto;
  overflow-x: hidden;

  // Custom scrollbar styling
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #d9d9d9;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $black-300;
    border-radius: 5px;
  }
}

.move-to-cart-items {
  display: flex;
  flex-direction: column;
}

//AddToCArt Modal

.add-to-cart-drawer-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 340px;
  height: 72vh;

  .cart-item-total {
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 8px;
    width: 87px;

    p {
      width: max-content;
      justify-content: center;
      align-items: center;
      font-family: "Helvetica-Neue-Bold";
      font-size: 24px;
      line-height: 100%;
      color: $white;
      font-weight: 700;
    }

    .count {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;
      width: 28px;
      height: 28px;
      background: $warning-1;
      border-radius: 50%;

      p {
        font-family: "Helvetica-Neue-Regular";
        font-size: 16px;
        line-height: 100%;
        color: $black-100;
        font-weight: 500;
      }
    }
  }

  .empty-cart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 28px;
    width: 323px;

    p {
      width: 200px;
      height: 38px;
      font-family: "Helvetica-Neue-Regular";
      font-size: 16px;
      line-height: 120%;
      text-align: center;
      color: #848486;
    }
  }

  .cart-item-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 350px;
    height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;

    // Custom scrollbar styling
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: $black-100;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-550;
      border-radius: 27px;
    }

    .cart-item-card {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 0px 0px 24px;
      gap: 24px;
      width: 315px;
      height: max-content;
      border-bottom: 0.75px solid $gray-720;

      .cart-item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%;
        // height: 69px;

        .cart-item-image {
          width: 69px;
          height: 69px;
          background: $gray-350;
          border-radius: 8px;
        }

        .cart-item-content-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          padding: 0px;
          gap: 23px;
          width: 248px;
          // height: 69px;

          .cart-item-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            padding: 0px;
            gap: 8px;
            width: 248px;
            // height: 69px;

            .text-content {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: flex-start;
              padding: 0px;
              gap: 8px;
              width: 248px;
              // height: 42px;

              .title-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                gap: 8px;

                .title {
                  width: 180px;
                  font-family: "Helvetica-Neue-Medium";
                  font-size: 16px;
                  line-height: 100%;
                  color: $gray-150;
                  font-weight: 500;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }

                .desciption {
                  width: 180px;
                  font-family: "Helvetica-Neue-Regular";
                  font-size: 14px;
                  line-height: 125%;
                  color: $gray-150;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }

              .price {
                width: max-content;
                font-family: "Helvetica-Neue-Medium";
                font-size: 24px;
                line-height: 100%;
                color: $white;
                font-weight: 500;
              }
            }

            .cart-item-option-container {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding: 0px;
              gap: 12px;
              width: 247px;
              // height: 16px;

              .cart-item-size-container {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 0px;
                gap: 12px;
                width: 55px;
                // height: 16px;

                .cart-item-size {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding: 2px 2px 2px 4px;
                  gap: 2px;
                  width: 55px;
                  // height: 16px;
                  background: #f7f7f7;
                  border-radius: 2px;

                  p {
                    width: max-content;
                    font-family: "Helvetica-Neue-Medium";
                    font-size: 12px;
                    line-height: 100%;
                    color: #000000;
                  }
                }
              }
            }
          }
        }
      }

      .cart-item-bottom {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        gap: 12px;
        width: max-content;
        background: $white;
        border-radius: $border-radius-1;
        cursor: pointer;

        p {
          font-family: "Helvetica-Neue-Regular";
          font-size: 16px;
          line-height: 110%;
          text-align: start;
          color: $black-100;
          max-width: 15em;
        }
      }
    }
  }

  .add-to-cart-box {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 327px;
    // height: 70px;
    background: #121212;
    border: 0.75px dashed #c4c4c4;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      p {
        font-size: 16.2px;
      }
    }

    p {
      width: max-content;
      // height: 13px;
      font-family: "Helvetica-Neue-Regular";
      font-size: 16px;
      line-height: 80%;
      color: #f7f7f7;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }
}

.dashboard_slider {
  .slick-track {
    margin-left: 0%;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .slider-corner-shadow {
    height: 100%;
    position: absolute;
    width: 1%;
    z-index: "1";
    background: linear-gradient(270deg, #121212 0%, rgba(30, 26, 18, 0) 100%);
  }
  &.explore_slider {
    overflow: hidden;
    z-index: 1;
    // .slick-slider {
    //   overflow: hidden;
    // }

    .slick-track {
      gap: 1.5rem;

      &::before,
      &::after {
        display: none;
      }
    }
  }
}

.subtotal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 327px;
  height: 48px;
  // margin: -1rem auto;
  position: absolute;
  bottom: 100px;

  &.cart {
    height: 25vh;
    position: static;
  }

  .subtotal {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 12px;
    gap: 8px;
    width: 324px;
    border-top: 0.75px solid $gray-720;

    p {
      width: max-content;
      font-family: "Helvetica-Neue-Regular";
      font-size: 24px;
      line-height: 100%;
      color: $gray-350;
    }

    span {
      width: max-content;
      // height: 24px;
      font-family: "Helvetica-Neue-Regular";
      font-size: 24px;
      line-height: 100%;
      color: #ffffff;
    }
  }

  .checkout-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 32px;
    height: 70px;
    // margin-top: 18rem !important;

    .btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      width: 327px;
      height: 70px;
      background: $black-100;
      border-radius: $border-radius-1;
      &.cart {
        border: 0.75px dashed $gray-520 !important;
        background-color: $black-200;
      }

      &:disabled {
        background: $gray-350;
        opacity: 1 !important;
        span {
          color: $gray-550;
        }
      }

      span {
        width: max-content;
        height: 16px;
        font-family: "Helvetica-Neue-Medium";
        font-size: 16px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.005em;
        color: $white;
      }
    }
  }
}

// People-you-follow

.people-you-follow {
  padding-left: 1%;

  //Profile View Card
  .profile {
    .profile-container {
      width: 100%;
      padding: 38px 0px;
      // border: 2px solid rgba(240, 240, 240, 0.13);
      border-radius: 8px;
      min-height: 300px;
      max-height: max-content;

      .profile-card {
        display: flex;
        justify-content: center;
        width: 80%;
        min-width: 70%;
        margin: 0 auto;

        .row-container {
          display: flex;
          flex-direction: row;
          min-height: 217px;

          @media screen and (max-width: 1000px) {
            .row-container {
              flex-direction: column !important;
              width: 100%;
            }
          }

          .title-btn-container {
            display: flex;
            flex-direction: column;

            .profile-card-name-container {
              display: flex;
              align-items: center;
              padding: 0px;
              gap: 4px;
              width: max-content;

              .profile-card-name {
                font-family: "Helvetica-Neue-Bold";
                font-size: 36px;
                color: $gray-150;
                font-weight: 700;
              }

              .profile-card-username {
                font-family: "Helvetica-Neue-Medium";
                font-size: 24px;
                line-height: 28px;
                color: rgba(217, 217, 217, 0.44);
              }
            }

            .profile-card-buttons {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              padding: 0px;
              gap: 18px;
              width: 190px;

              .profile-follow-btn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8px 12px;
                gap: 12px;
                width: 104px;
                height: 32px;
                background: $black-300;
                border-radius: $border-radius-1;
                border: none;

                span {
                  width: max-content;
                  height: 16px;
                  font-family: "Helvetica-Neue-Bold";
                  font-size: 16px;
                  line-height: 100%;
                  text-align: center;
                  color: $white;
                  font-weight: 700;
                }
              }

              .profile-contact-btn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8px 12px;
                gap: 12px;
                width: 87px;
                border: 1px solid $white;
                border-radius: $border-radius-1;
                background-color: transparent;

                span {
                  width: max-content;
                  height: 16px;
                  font-family: "Helvetica-Neue-Regular";
                  font-size: 16px;
                  line-height: 100%;
                  display: flex;
                  align-items: center;
                  text-align: center;
                  color: $white;
                }
              }
            }
          }

          .profile-card-items {
            font-family: "Helvetica-Neue-Regular";
            font-size: 18px;
            line-height: 100%;
            color: $gray-520;
          }

          .profile-card-desc {
            font-family: "Helvetica-Neue-Regular";
            font-size: 16px;
            line-height: 130%;
            color: $gray-520;
          }

          .profile-card-values {
            font-family: "Helvetica-Neue-Bold";
            font-weight: 700;
            font-size: 18px;
            line-height: 100%;
            letter-spacing: 0.005em;
            color: $gray-350;
          }
        }
      }
    }

    @media screen and (min-width: 400px) and (max-width: 1200px) {
      .profile-container .profile-card {
        width: 90% !important;
      }
    }
  }

  // List
  .people-heading {
    font-family: "Helvetica-Neue-Bold";
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    color: $white;
  }

  .list-card {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 12px;
    width: 262px;
    height: 314px;
    // border: 1px solid $gray-520 !important;
    border-radius: 16px;
    cursor: pointer;

    .list-card-options {
      display: none;
    }

    &:hover .list-card-options {
      display: flex;
      position: absolute;
      width: 24px;
      height: 24px;
      right: 1.2rem;
      top: 1rem;
      transform: rotate(-90deg);
      z-index: 2;
    }

    .list-card-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0px;

      .image-full {
        width: 100%;
        height: 237px;
        // border-radius: 15px 15px 0px 0px;
        object-fit: contain;
        background-color: $white;
      }

      .list-card-text-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 24px 12px 12px;
        gap: 12px;
        width: 262px;
        height: 65px;

        .list-card-text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 8px;
          width: 100%;
          height: 44px;

          .list-card-text-title {
            font-size: 19px;
            line-height: 1.06;
            color: $white;
            font-weight: 500;
          }

          span {
            font-size: 16px;
            line-height: 100%;
            color: $gray-350;
          }
        }

        .heart-icon {
          width: 20px;
          height: 17px;
        }
      }
    }

    &:hover .list-card-content > .image-full {
      filter: brightness(0.5);
    }
  }
}

// Followers And Follw=owing Modal

.profile-modal-card {
  // width: 100% !important;
  padding: 0%;

  .profile-modal-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.005em;
    color: $black;
    text-align: center;
  }

  //sort button
  .sort-button-container {
    position: relative;

    .sort-button {
      display: flex;
      align-items: center;
      color: $gray-720;
      border-radius: $border-radius-1;
      cursor: pointer;
      font-family: "Helvetica-Neue-Regular";
      font-size: 16px;
      line-height: 100%;
      padding: 6px 12px;

      .sort-icon {
        width: 1rem;
        margin-left: 0.5rem;
      }

      span {
        margin-right: 10px;
        color: $gray-720;
        font-size: 16px;
      }
    }

    .sort-menu {
      position: absolute;
      top: 100%;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 177.09px;
      background: $white;
      border-radius: 8px;

      &.MuiMenu-paper {
        padding: 0.5rem;
      }

      ul {
        width: 100%;
        list-style: none;
        padding: 1rem;
        margin: 0;

        li {
          font-family: Helvetica-Neue-Regular;
          font-size: 16px;
          line-height: 80%;
          color: $white;
          padding: 0.8rem;
          cursor: pointer;

          &:hover {
            background-color: #ddd;
          }

          &.selected {
            background-color: #333;
            color: #fff;
          }
        }
      }
    }
  }

  .scrollable-div {
    width: auto;
    height: auto;
    max-height: 58vh;
    overflow-y: auto;
    overflow-x: hidden;

    // Custom scrollbar styling
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: $gray-300;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $black;
      border-radius: 5px;
    }

    .profile-div {
      display: flex;
      align-items: center;
      padding: 5px 9px;

      .profile-details-name {
        display: flex;
        flex-direction: row;
        width: 200px;
        font-family: Helvetica-Neue-Medium;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0.005em;
        color: $black;

        .follow-btn {
          width: 45px;
          margin-left: 15px;

          span {
            font-family: "Helvetica-Neue-Regular";
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.005em;
            color: $warning-2;
          }
        }
      }

      .profile-details-username {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.005em;
        color: $gray-530;
      }
    }

    .profile-pic {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 15px;
    }

    .profile-details {
      flex-grow: 1;
    }

    .unfollow-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      padding: 0 16px;
      height: 26px;
      border: unset;
      border-radius: 70px;
      font-size: 16px;
      color: $black;
      font-weight: 700;
      background: transparent;
      margin-right: 10px;
      border: 1px solid $black;
    }
  }

  // Action Remove or Unfollow Modal

  .action-modal-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    padding: 32px;
    gap: 24px;
    width: 427px;
    height: 464px;

    .profile-detail-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 12px;

      .profile-detail-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 4px;

        p {
          font-family: "Helvetica-Neue-Medium";
          font-size: 20px;
          line-height: 23px;
          font-weight: 500;
          color: $black-100;
        }

        span {
          font-family: "Helvetica-Neue-Medium";
          font-size: 14px;
          line-height: 16px;
          font-weight: 500;
          color: $gray-550;
        }
      }
    }

    .action-info-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      padding: 0px;
      gap: 8px;

      p {
        font-family: "Helvetica-Neue-Bold";
        font-size: 16px;
        line-height: 130%;
        text-align: center;
        font-weight: 700;
        letter-spacing: 0.005em;
        color: $black-100;
      }

      span {
        font-family: "Helvetica-Neue-Regular";
        font-size: 16px;
        line-height: 130%;
        text-align: center;
        letter-spacing: 0.005em;
        color: $gray-720;
      }
    }

    .action-button-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 8px;

      width: 260px;
      height: 74px;

      .action-remove {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        gap: 12px;
        width: 260px;
        height: 40px;
        background-color: $danger;
        border: unset;
        border-radius: $border-radius-1;

        span {
          height: 16px;
          font-family: "Helvetica-Neue-Bold";
          font-size: 16px;
          line-height: 100%;
          display: flex;
          font-weight: 700;
          align-items: center;
          text-align: center;
          color: $white;
        }
      }

      .action-cancel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        gap: 12px;
        width: 70px;
        height: 34px;
        border: none;
        border-radius: 8px;
        background-color: transparent;
        color: $gray-720;
      }
    }
  }
}

// For you container

.foryou_container {
  .home-card:hover .image-text {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(16, 10, 0, 0) 0.01%,
      #100a00 100%
    );
  }
  .user-info {
    display: none;
  }

  .cart-heart {
    display: none;
  }

  .home-card:hover .user-info {
    display: block !important;
  }

  .home-card:hover .cart-heart {
    display: flex !important;
  }
  .home-card:hover .liked-icon {
    display: none;
  }
}

// People-profile

.people-profile {
  padding-left: 1%;

  .nav-header {
    font-family: Helvetica-Neue-Regular !important;
    line-height: 125%;
    color: #d9d9d9;
  }

  .profile-detail {
    // profile-detail-box
    .profile-detail-box {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 43px 12px 0px;
      gap: 12px;
      height: 76px;
      border-bottom: 0.75px solid $gray-720;

      img {
        width: 64px;
        height: 64px;
      }

      .profile-detail-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 12px;
        width: max-content;

        p {
          font-family: "Helvetica-Neue-Regular";
          font-size: 24px;
          line-height: 100%;
          color: $white;
          font-weight: 500;
        }

        span {
          font-family: "Helvetica-Neue-Regular";
          font-size: 18px;
          line-height: 100%;
          color: $gray-350;
          font-weight: 500;
        }
      }
    }
  }

  .profile-lists-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    .profile-list-head-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      gap: 24px;
      width: 100%;
      // height: 24px;

      .heading-texts {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 24px;
        width: max-content;

        .explore-heading {
          font-family: "Helvetica-Neue-Bold";
          font-weight: 700;
          font-size: 32px;
          line-height: 100%;
          color: $white;
        }

        span {
          height: 13px;
          font-family: "Helvetica-Neue-Regular";
          font-size: 18px;
          color: $white;
          line-height: 100%;
        }
      }

      .list-heading-options {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
        width: 104px;
        height: 24px;

        img {
          cursor: pointer;
        }
      }
    }
  }

  .list-item-card-container {
    border-radius: 16px;

    .card-maindiv {
      width: 100%;
      height: 100%;
      max-height: 320px;
      cursor: pointer;

      .list-item-card-text-container {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0;
        position: absolute;
        left: 0;
        bottom: 0;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(16, 10, 0, 0) 0.01%,
          #100a00 100%
        );

        border-radius: 0 0 13px 13px;
        gap: 8px;

        p {
          height: 21px;
          font-size: 20px;
          line-height: 100%;
          letter-spacing: 0.005em;
          color: #ffffff;
        }

        span {
          // height: 17px;
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          letter-spacing: 0.005em;
          color: #ffffff;
        }

        .price {
          height: 21px;
          font-family: Helvetica-Neue-Medium !important;
          font-size: 20px;
          line-height: 100%;
          letter-spacing: 0.005em;
          color: #ffffff;
        }
      }

      .home-card-options {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 32px;
        top: 27px;
        cursor: pointer;
        display: none;
      }

      .list-item-card:hover .home-card-options {
        display: block !important;
      }

      .home-card-text-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0px;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(16, 10, 0, 0) 0.01%,
          #100a00 100%
        );
        border-radius: 0 0 13px 13px;
      }

      .list-item-card:hover svg {
        display: block;
      }

      .list-item-card:hover .list-card-content > img {
        filter: brightness(0.5);
      }
      .list-item-card:hover .list-card-content > .image {
        filter: brightness(0.5);
      }
    }
  }
}

// Search Results Page

.search-results {
  padding-left: 1%;

  .results-heading {
    font-family: "Helvetica-Neue-Medium";
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    color: $white;
  }
}

.grid-auto-flow-col {
  grid-auto-flow: column;
}

.container-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  grid-auto-rows: 1fr;
  grid-gap: 15px;

  &.profile-lists {
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    justify-content: stretch;
  }

  &.profile-users-list {
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    justify-content: stretch;
  }

  &.profile-brandss-list {
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    justify-content: stretch;
  }

  &.your-list {
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    justify-content: stretch;
    grid-gap: 15px;
  }

  &.profile-users-list {
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    justify-content: stretch;
  }
  &.list-item-card-container {
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    justify-content: stretch;
  }
  &.dashboard-wishlist {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    justify-content: stretch;
  }
  &.search-result {
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    justify-content: stretch;
  }

  &.wardrobe-purchase {
    grid-template-columns: repeat(auto-fill, minmax(195px, 210px));
    justify-content: stretch;
    grid-gap: 24px;
    padding-left: 15px;
  }
  &.wardrobe-active-cart {
    grid-template-columns: repeat(auto-fill, minmax(195px, 210px));
    justify-content: stretch;
    grid-gap: 24px;
  }
  &.wardrobe-outfit {
    grid-template-columns: repeat(auto-fill, minmax(260px, 270px));
    justify-content: stretch;
  }

  &.search-profile {
    grid-template-columns: repeat(auto-fill, minmax(344px, 1fr));
    justify-content: stretch;
  }

  .card {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    background: transparent;
    border: none;
    justify-content: flex-start;
    align-items: baseline;

    .img-wrapper {
      width: 262px;
      height: 325px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover !important;
      // border-radius: 0px 0px 15px 15px;
    }
  }
  #drag-to-cart-button.drag-over {
  background-color:#e5b15e;
  transition: background-color 0.3s ease;
}

}

.disclosure-statement {
  font-size: 15px;
  color: #f8f8f8;
  // padding: 10px 15px;
  text-align: center;
  // position: absolute;
  // transform: translateY(15vh);
}

.container-fluid {
  padding: 0
}

.sale-price{
  color: rgb(227, 58, 58) !important;
  text-decoration: line-through;
  font-size: 0.9em;
  margin-left: 0.5rem;
}

.similar-items-wrapper {
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
}

.similar-item-card {
  padding: 10px;
}

.similar-item-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
}

.similar-item-details {
  margin-top: 10px;
}

.brand-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.item-title {
  font-size: 0.9em;
  color: #666;
}

.product-card-wrapper.purchased {
  opacity: 0.6;
  filter: grayscale(100%);
  pointer-events: none;
}

.purchased-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  z-index: 5;
  border-radius: 8px;
}

.purchased-badge {
  background-color: var(--bs-success);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.9rem;
}

.product-card-wrapper.purchased {
  opacity: 0.6;
  filter: grayscale(100%);
  pointer-events: none;
}

.product-card-wrapper.purchased .product-info-container {
  background-color: rgba(0, 0, 0, 0.7);
}

.icon-button {
  cursor: pointer;
  position: relative;
  svg {
    stroke: $white;
    &:hover {
      stroke: $gray-250;
    }
  }

  .feedback-icon:hover {
    color: $gray-250;
  }

  .notification-dot {
    position: absolute;
    right: -50%;
    top: -35%;
    border-radius: 50%;
    background: $yellow-100;
    height: 20px;
    width: 20px;
    color: $black;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.create-list-btn {
  svg {
    stroke: $white;
  }

  &:hover {
    svg {
      stroke: $gray-250;
    }
  }
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.giftlist {
  .nav-header {
    margin-bottom: 1.5rem;
  }

  .dropdowns-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: auto;
  }

  .wishlist-heading {
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
  }

  .your-lists-container {
    margin-top: 1rem;
  }

  .select-gift-button {
    &:hover {
      opacity: 0.9;
      transform: translateY(-1px);
    }
    transition: all 0.2s ease;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  .action-button-container {
    display: flex;
    align-items: center;
  }
}

.recommended-users-list {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, 180px); // Updated to 180px
  gap: 40px; // Increased gap between items
  overflow-x: hidden;
  padding: 10px 0;
  width: 100%;
  
  &::-webkit-scrollbar {
    display: none;
  }
  
  .recommended-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 12px;
    width: 180px; // Updated width to match grid
    cursor: pointer;

    .user-avatar {
      width: 180px; // Updated to full width
      height: 180px;
      border-radius: 50%;
      object-fit: cover;
    }

    .user-name {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
}
.image-grid {
  height: 100%;
  width: 100%;
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 0;
    width: 100%;
    height: 100%;
  }
  
  .grid-item {
    overflow: hidden;
    position: relative;
  }
  
  .grid-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }  
}

.home-banner {
  height: 300px;
  background-size: cover;
  .carousel-item-custom {
    padding: 0;
}
}

// Tutorial styles
.tutorial-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  pointer-events: none;
}

.tutorial-dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 998;
}

.tutorial-fade-out {
  animation: fade-out 3s forwards;
}

@keyframes fade-out {
  0% { opacity: 1; }
  70% { opacity: 1; }
  100% { opacity: 0; }
}

.tutorial-tooltip {
  position: absolute;
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  width: 320px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  pointer-events: auto;
  z-index: 1001;
  animation: tooltip-fade-in 0.3s ease;

  p {
    margin: 0 0 20px 0;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 500;
    color: #333;
  }
}

.tutorial-feedback-message {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 12px;
  padding: 30px;
  width: 500px;
  text-align: center;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  pointer-events: auto;
  z-index: 1001;
  animation: tooltip-fade-in 0.3s ease;

  h3 {
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: 600;
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 25px;
    color: #555;
  }
  
  .tutorial-buttons {
    justify-content: center;
    
    button {
      min-width: 120px;
    }
  }
}

.tutorial-final-message {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  padding: 20px;
  width: 100%;
  text-align: center;
  z-index: 1001;
  
  h2 {
    font-size: 32px;
    font-weight: 700;
    color: white;
    text-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    letter-spacing: 1px;
  }
}

@keyframes tooltip-fade-in {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.tutorial-buttons {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  
  button {
    padding: 10px 18px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.2s ease;
    pointer-events: auto;
    
    &:first-child {
      background-color: #f5f5f5;
      color: #555;
      
      &:hover {
        background-color: #e8e8e8;
      }
    }
    
    &:last-child {
      background-color: #000;
      color: white;
      
      &:hover {
        background-color: #333;
      }
    }
  }
}

.tutorial-arrow {
  position: absolute;
  width: 0;
  height: 0;
}

.tutorial-position-right {
  .tutorial-arrow {
    left: -12px;
    top: 30px;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid white;
  }
}

.tutorial-position-left {
  .tutorial-arrow {
    right: -12px;
    top: 30px;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid white;
  }
}

.tutorial-welcome-message {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 12px;
  padding: 30px;
  width: 500px;
  text-align: center;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.2);
  pointer-events: auto;
  z-index: 1001;
  animation: welcome-appear 0.5s ease;

  .welcome-initial-header {
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #000000;
    letter-spacing: 0.5px;
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 25px;
    color: #555;
  }
  
  .tutorial-buttons {
    justify-content: center;
    
    button {
      min-width: 120px;
      font-weight: 600;
      background-color: #000;
    }
  }
}