.card {
  border: 0;
  background: $white;
  border: 1px solid $black-100;
  // box-shadow: $box-shadow-7;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $white;

  &-header {
    font-family: "Helvetica-Neue-Bold";
    font-style: normal;
    background-color: transparent;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    text-align: center;
    color: $black-100;
    padding-top: 48px;
    padding-bottom: none;
    border: none;
  }
  .form-label-text {
    font-size: 16px;
    color: $gray-720;
    font-weight: 400;
  }
  .login-submit-btn {
    background: $gray-350;
    border-radius: $border-radius-1;
    border: none;

    span {
      font-family: "Helvetica-Neue-Medium";
      font-size: 16px;
      color: $gray-550;
    }
  }
  .login-submit-btn-filled {
    background: $black-100;
    border-radius: $border-radius-1;
    border: none;

    span {
      font-family: "Helvetica-Neue-Medium";
      font-size: 16px;
      color: $white;
    }
  }
  .forget-password-text {
    all: unset;
    font-size: 14px;
    line-height: 100%;
    color: $green-100;
    float: right;
    cursor: pointer;
  }

  .continue-as-guest {
    font-family: "Helvetica-Neue-Regular";
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: $green-100;
    cursor: pointer;
  }

  .or {
    font-family: "Helvetica-Neue-Regular";
    font-size: 16px;
    color: $gray-550;
  }
  .sign-up-text {
    font-family: "Helvetica-Neue-Medium";
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: $black-200;
    font-weight: 500;
  }
  .sign-up-card-info {
    font-family: "Helvetica-Neue-Regular";
    font-size: 12px;
    line-height: 125%;
    color: $gray-550;
  }
  .social-login-icon {
    width: 44px;
    height: 44px;
    color: #374151;
    margin-right: 20px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }

  //varification-card
  .card-back-btn {
    display: inline-flex;
    position: absolute;
    left: 1px;
    color: $green-100;
    cursor: pointer;
    span {
      color: $green-100;
      font-family: "Helvetica-Neue-Regular";
      font-size: 18px;
    }
  }
  .phone-verify-heading {
    font-family: "Helvetica-Neue-Bold";
    font-weight: 700;
    font-size: 24px;
    color: $black-100;
  }
  .phone-number-text {
    font-family: "Helvetica-Neue-Regular";
    font-size: 16px;
    color: $gray-720;
    line-height: 100%;
    font-weight: 500;
    span {
      color: $gray-750;
    }
  }
  .resend-passcode {
    font-family: "Helvetica-Neue-Regular";
    line-height: 100%;
    width: max-content;
    color: $green-100;
    cursor: pointer;
  }
  .email-verify-text {
    font-family: "Helvetica-Neue-Regular";
    font-size: 16px;
    line-height: 100%;
    color: $gray-550;

    .p-text {
      font-family: "Helvetica-Neue-Regular";
      font-size: 16px;
      color: $black-100;
    }

    li {
      line-height: 125%;
      font-size: 16px;
      font-family: "Helvetica-Neue-Regular";
    }
  }
  .try-again-text {
    font-family: "Helvetica-Neue-Regular";
    font-style: normal;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: $green-100;
  }
}

.list-popup-card {
  max-width: 500px;
  padding: 24px 50px 56px 50px;
  border-radius: 16px;
  border-color: transparent;
  background: $white;
  display: flex;
  flex-direction: column;
}

.popup-title-text {
  font-weight: 700;
  color: $black;
  font-size: 35px;
  font-family: "Helvetica-Neue-Bold";
}

.restore-popup-card {
  max-width: 590px;
  min-width: 590px;
  padding: 72px 88px 100px 88px;
  background: $white;
  border-color: transparent;
  display: flex;
  border-radius: 16px;
  flex-direction: column;
}

.restore-popup-header {
  color: $black;
  font-size: 24px;
  font-family: "Helvetica-Neue-Medium";
}

.steps-wrapper {
  display: flex;
  gap: 10px;
  margin-bottom: 50px;
}

.step-indicator {
  width: 100%;
  height:14px;
  border-radius: 5px;
  background: #808080;

  &.active{
    background: $black;
  }
}

.restore-email-reciever {
  font-size: 20px;
  font-weight: 400;
  color: $gray-530;
  text-align: center;

  span{
    color: $black;
    margin-left: 4px;
  }

  font {
    color: $black;
    margin-left: 4px;
    text-decoration: underline;
    font-weight: 600;
  }
}

.popup-warn-text {
  font-size: 16px;
  font-weight: 400;
  color: $gray-550;
  text-align: center;
  width: 100%;
}

.item-description-card {
  min-width: 848px;
  width: 848px;
  background: $white;
  border: unset;
  border-radius: $border-radius-card;
  padding: 25px;
  overflow-x: hidden;

  .col {
    padding: 0; 
  }
  
// ITEM DESCRIPTION MODAL
  .item-description-content-wrapper {
    width: 100%;
    height: 423px;
    gap: 22px;
    display: flex;
    flex-wrap: nowrap; // Prevent items from wrapping
    // overflow-x: auto; // Allow horizontal scrolling if items overflow
  }

  .image-wrapper {
    position: relative;
    border-radius: $border-radius-card;
    overflow: hidden;
    max-width: 325px;
    width: 325px;
    height: inherit;
    background: $gray-120;
  }

  .image-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: $white;
    border-radius: 16px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    
  }

  .share-button {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D9D9D980;
    position: absolute;
    bottom: 16px;
    right: 16px;
    cursor: pointer;
  }

  .item-description-wrapper {
    max-width: 451px;
    position: relative;

    .close-icon-wrapper {
      position: absolute;
      top: 0px; // Adjust as needed
      right: -40px; // Adjust as needed
      cursor: pointer;
    }
  }

  .item-title-text {
    font-size: 26px;
    font-weight: 700;
    color: $black;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; // Limit to two lines
    line-clamp: 2; // Standard property for compatibility
    -webkit-box-orient: vertical;
  }

  .item-shop-info-text {
    font-size: 20px;
    font-weight: 400;
    color: $black;
    height: 30px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .add-text {
    font-size: 18px;
    font-weight: 400;
    color: $black;
    margin-top: 18px;
  }

  .list-tag {
    padding: 8px 16px;
    border-radius: 70px;
    border: 1px solid $black;
    color: black;
    // font-weight: 600;
    cursor: pointer;
    overflow: hidden;
    height: 32px;
    display: flex;
    align-items: center;
    max-height: 32px;
    transition: background-color 0.2s ease;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 250px;
    }

    &.active {
      color: $white;
      background: $black;
    }

    &:hover {
      background: #efefef;
    }
  }

  .add-list-tag-btn {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background: $gray-750;
    }
  }

  .user-tags-wrapper {
    height: 160px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 14px;
      height: 18px;
    }
    &::-webkit-scrollbar-thumb {
      height: 6px;
      border: 4px solid transparent;
      background-clip: padding-box;
      border-radius: 7px;
      background-color: $gray-400;
    }
    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }
  .similar-products-section {
  
    .similar-products-scroll {
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      justify-content: top;
    }
  
    .similar-product-item {
      display: inline-block;
      margin-right: 15px;
      width: 120px;
      cursor: pointer;
      padding: 5px;
      padding-bottom: 0;
      border-radius: 8px;
      transition: background-color 0.2s ease; // Add transition for background-color and border-color

      &:hover {
        border-radius: 8px;
        background-color: #efefef;
      }
  
      img {
        width: 100%;
        height: 120px;
        object-fit: cover;
        border-radius: 8px;
      }
  
      .item-title,
      .brand-name,
      .price {
        font-size: 0.8rem;
        font-weight: 400;
        color: #000; // Adjust the color as needed
        margin: 0.5rem 0;
        white-space: normal;
      }

      .item-title {
        font-weight: 500;
        font-size: 0.9rem;
      }
  
      .item-title {
        display: -webkit-box;
        -webkit-line-clamp: 2; // Limit to two lines
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.2rem; // Adjust the line height
        max-height: 2.4rem;
      }
  
      .brand-price-container {
        align-items: center;
        margin-top: 0.5rem;
      }
    }
  
    .show-all {
      cursor: pointer;
      color: #007bff;
      font-size: 1rem;
      font-weight: 400;
    }
  }
}

// CART MODAL
.cart-content-wrapper {
  height: 100%;
  position: relative;
  overflow: hidden;

  .cart-header {
    font-size: 50px;
    font-weight: 700;
    color: $black;
  }
  
  .cart-text {
    font-size: 18px;
    font-weight: 600;
    color: $black;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cart-subtext {
    font-size: 18px;
    font-weight: 400;
    color: $gray-530;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cart-item-price {
    font-size: 16px;
    font-weight: 400;
    color: $black;
  }

  .item-list-srcoll-wrapper{
    overflow-y: auto;
    overflow-x: hidden;
    height: 520px;

    &::-webkit-scrollbar {
      width: 14px;
      height: 18px;
    }
    &::-webkit-scrollbar-thumb {
      height: 6px;
      border: 4px solid transparent;
      background-clip: padding-box;
      border-radius: 7px;
      background-color: $gray-400;
    }
    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }

  .list-item-wrapper {
    height: 100px;
    display: flex;
    margin-bottom: 10px;
  }

  .image-wrapper {
    height: inherit;
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    overflow: hidden;
    border-radius: 5px;
    background: $gray-350;
  }

  .image-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: $gray-120;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .item-info-wrapper {
    max-width: 256px;
    padding: 6px auto;
  }

  .checkout-btn {
    position: absolute;
    bottom: 40px;
    height: 48px;
    background: $black;
    padding: 0 48px;
    color: $white;
    border-radius: 90px;
    border: unset;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.list-card-wrapper {
  grid-template-columns: repeat(auto-fill, 1fr); // Set the grid columns
  grid-auto-flow: column;
  box-sizing: border-box; // Ensure padding and border are included in the width and height
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden; // Ensure content does not overflow
}

// LIST CARD
.product-list-card-wrapper {
  min-width: 225px; // Set a fixed width
  
  .list-card-container {
    width: 100%;
    background: $white;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    aspect-ratio: 1/1;

    &:hover {
      button {
        visibility: visible;
      }

      .list-images-wrapper {
        filter: brightness(0.5);
        background: $white;
      }
    }
  }

    .dropdown-wrapper {
      filter: none;
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 50;

      button {
        visibility: hidden;
        position: absolute;
        right: 10px;
        top: 10px;
        transform: rotate(90deg);
      }


    }

    .heart-wrapper {
      display: flex;
      background: $white;
      align-items: center;
      border-radius: 16px;
      justify-content: center;
      overflow: hidden;
      aspect-ratio: 1/1;
    }

  .single-image {
    object-fit: contain;
    object-position: center;
    height: 100%;
    width: 100%;
  }

  .multi-image-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-columns: "50%";
  }

  .multi-image {
    aspect-ratio: 1/1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .list-name-text {
    max-width: 130px;
    font-size: 18px;
    color: $white;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .list-owner-text {
    font-size: 14px;
    max-width: 130px;
    color: $gray-310;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

//USER CARD
.user-list-card-wrapper {
  width: 100%;

  .image-wrapper {
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background: url(https://via.placeholder.com/120);
    background-size: cover;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .username-text {
    max-width: 75%;
    font-size: 18px;
    color: $white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .profile-text {
    max-width: 75%;
    font-size: 14px;
    color: $gray-310;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

//BRAND CARD
.brand-list-card-wrapper {
  width: 100%;
  cursor: pointer;
  

  .image-wrapper {
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    background-repeat: no-repeat;
    background: url(https://via.placeholder.com/120);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover; // Ensures the image covers the entire area
    }
  }

  .brandname-text {
    max-width: 75%;
    font-size: 18px;
    color: $white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .brand-text {
    max-width: 75%;
    font-size: 14px;
    color: $gray-310;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

//PRODUCT CARD
.product-card-wrapper {
  position: relative;
  height: 300px;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    .image-container {
      filter: brightness(0.5);
    }

    .product-info-container {
      visibility: visible;
    }
  }

  .image-container {
    width: 100%;
    height: 100%;
  }

  .truncate-width {
    width: 70%;
  }

  .product-info-container {
    z-index: 10;
    visibility: hidden;
    top: 0;
    position: absolute;
    padding: 14px 18px;
    height: inherit;
    width: 100%;

    .visible-like-btn {
      visibility: visible;
    }
  }
}



